var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { AutoAwesome, Call, CallEnd, Cancel, CheckCircle, Laptop, Paid, Person, } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Button from 'components/library/Button';
import Dropdown from 'components/library/Dropdown';
import DropdownItem from 'components/library/DropdownItem';
import getErrorMessage from 'utils/error';
import { addToast } from 'services/store/features/toasts/actions';
import { getLeadCalendarUrl } from 'services/controller/leads';
import { toBackLead } from 'services/network/adaptor/leads';
import { createLog, updateLeadById } from 'services/network/api/leads';
import { getCustomerById, getCustomersByQuery } from 'services/network/api/customers';
import { fromBackCustomer } from 'services/network/adaptor/customers';
import STATUS from './utils';
import styles from './LeadsTunnelCustomerCall.module.css';
import CallEndReasons from './CallEndReasons';
import VisioScheduler from 'components/VisioScheduler';
import { CUSTOMER_CARE_CALENDAR, SALES_CALENDAR } from 'constants/calendar';
import { logError } from 'utils/logs';
export default function LeadsTunnelCustomerCall(_a) {
    var _this = this;
    var lead = _a.lead, onCallEnd = _a.onCallEnd, iss = _a.iss;
    var _b = useState(false), isCallEnd = _b[0], setIsCallEnd = _b[1];
    var _c = useState(1), step = _c[0], setStep = _c[1];
    var _d = useState('no-call-answer'), reason = _d[0], setReason = _d[1];
    var _e = useState(''), other = _e[0], setOther = _e[1];
    var _f = useState(''), calendar = _f[0], setCalendar = _f[1];
    var _g = useState(false), isSmsSend = _g[0], setIsSmsSend = _g[1];
    var _h = useState(false), openVisioScheduler = _h[0], setOpenVisioScheduler = _h[1];
    var dispatch = useDispatch();
    useEffect(function () {
        var cancelToken = axios.CancelToken;
        var customerSource = cancelToken.source();
        var userSource = cancelToken.source();
        var getCalendar = function (inSource, inSourceUser) { return __awaiter(_this, void 0, void 0, function () {
            var data, userData, formattedData, err_1;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, getCustomersByQuery(inSource, { query: lead.email, type: 'ri' })];
                    case 1:
                        data = (_d.sent()).data;
                        if (!(data.length > 0 && data[0].id_ayomi)) return [3 /*break*/, 3];
                        return [4 /*yield*/, getCustomerById(inSourceUser, data[0].id_ayomi, data[0].email)];
                    case 2:
                        userData = (_d.sent()).data;
                        if (userData) {
                            formattedData = fromBackCustomer(userData);
                            setCalendar((_c = (_b = (_a = formattedData === null || formattedData === void 0 ? void 0 : formattedData.p2p) === null || _a === void 0 ? void 0 : _a.inCharge) === null || _b === void 0 ? void 0 : _b.ri) === null || _c === void 0 ? void 0 : _c.calendar);
                        }
                        _d.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _d.sent();
                        logError(err_1);
                        console.error(err_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (lead.type !== 'sdr') {
            getCalendar(customerSource, userSource);
        }
        return function () {
            customerSource.cancel('axios request cancelled');
            userSource.cancel('axios request cancelled');
        };
    }, [lead]);
    var scheduleCall = function (service) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            createLog(lead.id, 'buttonRdv');
            if (service === 'sales') {
                window.open(getLeadCalendarUrl(SALES_CALENDAR, lead, iss));
            }
            else if (service === 'ri') {
                window.open(getLeadCalendarUrl(calendar, lead, iss));
            }
            else if (service === 'visio') {
                setOpenVisioScheduler(true);
            }
            else {
                window.open(getLeadCalendarUrl(CUSTOMER_CARE_CALENDAR, lead, iss));
            }
            return [2 /*return*/];
        });
    }); };
    var closeVisioScheduler = function () { return setOpenVisioScheduler(false); };
    var onHangUp = function () {
        setIsCallEnd(true);
    };
    var selectReason = function (e) { return setReason(e.target.value); };
    var onOtherChange = function (e) { return setOther(e.target.value); };
    var close = function () { return setIsCallEnd(false); };
    var getOptions = function () { return Object
        .keys(STATUS)
        .filter(function (key) { return STATUS[key].types.includes(lead.type); })
        .map(function (key) { return (__assign(__assign({}, STATUS[key]), { key: key })); }); };
    var submit = function () { return __awaiter(_this, void 0, void 0, function () {
        var data, err_2;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    createLog(lead.id, (_a = STATUS[reason]) === null || _a === void 0 ? void 0 : _a.status, (reason === 'other') ? other : reason);
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, updateLeadById(toBackLead(__assign(__assign(__assign({}, lead), ((lead.meeting === 'calendrier') ? { meeting: 'csv' } : {})), { dailyCall: lead.dailyCall + (((_b = STATUS[reason]) === null || _b === void 0 ? void 0 : _b.daily) || 0), totalCall: lead.totalCall + (((_c = STATUS[reason]) === null || _c === void 0 ? void 0 : _c.total) || 0) }), ((_d = STATUS[reason]) === null || _d === void 0 ? void 0 : _d.status) || 'off', (reason !== 'other') ? reason : other))];
                case 2:
                    data = (_e.sent()).data;
                    if (reason === 'no-call-answer') {
                        setIsSmsSend(data.send_sms);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    err_2 = _e.sent();
                    logError(err_2);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Le statut du lead n\'a pas pu être mis à jour',
                        description: getErrorMessage(err_2),
                    }));
                    return [3 /*break*/, 5];
                case 4:
                    if (reason === 'no-call-answer') {
                        setStep(2);
                    }
                    else {
                        onCallEnd();
                    }
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [(isCallEnd)
                && (_jsx("div", { className: "fadeIn ".concat(styles.background), children: _jsx("div", { className: "bounceIn ".concat(styles.modal), children: (step === 1)
                            ? (_jsx(CallEndReasons, { reason: reason, selectReason: selectReason, options: getOptions(), other: other, onOtherChange: onOtherChange, submit: submit, close: close }))
                            : (_jsxs("div", { children: [isSmsSend
                                        ? (_jsxs("p", { className: styles.check, children: [_jsx(CheckCircle, {}), _jsx("span", { children: "Le SMS \u201Cne r\u00E9pond pas\u201C a bien \u00E9t\u00E9 envoy\u00E9" })] }))
                                        : (_jsxs("p", { className: styles.fail, children: [_jsx(Cancel, {}), _jsx("span", { children: "Le SMS \u201Cne r\u00E9pond pas\u201C n'a pas \u00E9t\u00E9 envoy\u00E9" })] })), _jsx(Button, { onClick: onCallEnd, className: styles.submit, children: "Passer au lead suivant" }), _jsx(Button, { variant: "ghost", onClick: close, className: styles.cancel, children: "Annuler" })] })) }) })), openVisioScheduler
                && _jsx(VisioScheduler, { email: lead.email, onClose: closeVisioScheduler, projectId: 0 }), _jsxs("div", { className: "fadeIn ".concat(styles.call), children: [_jsxs("p", { className: styles.hint, children: [_jsx(Call, { className: styles.icon }), "Appel en cours"] }), _jsxs("div", { className: styles.row, children: [_jsxs(Dropdown, { className: styles.dropdown, value: "Prendre rendez-vous", onChange: scheduleCall, children: [lead.type === 'waiting_visio'
                                        && (_jsxs(DropdownItem, { value: "visio", children: [_jsx(Laptop, { className: styles['dropdown-icon'] }), "Rendez-vous visio"] })), _jsxs(DropdownItem, { value: "sales", children: [_jsx(Person, { className: styles['dropdown-icon'] }), "Sales"] }), _jsxs(DropdownItem, { value: "care", children: [_jsx(AutoAwesome, { className: styles['dropdown-icon'] }), "Customer care"] }), (lead.type !== 'sdr' && calendar)
                                        && (_jsxs(DropdownItem, { value: "ri", children: [_jsx(Paid, { className: styles['dropdown-icon'] }), "Relation investisseur"] }))] }), _jsxs(Button, { type: "primary", onClick: onHangUp, children: [_jsx(CallEnd, { className: styles.icon }), "Fin de l'appel"] })] })] })] }));
}
