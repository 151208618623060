import axios from 'services/network/axiosClient';
export var createLog = function (id, action, comment) {
    if (comment === void 0) { comment = ''; }
    return axios.post('/log', { action: action, comment: comment, id_lead: id });
};
export var getNextLead = function (source) { return axios.get('/lead', { cancelToken: source.token }); };
export var updateLeadById = function (data) { return axios.put('/lead', data); };
export var uploadFacebookLeads = function (data) { return axios.post('/lead/import', data); };
export var getLeadsByQuery = function (source, search) { return axios.post('/lead/search', { search: search }, { cancelToken: source.token }); };
export var setPastMeetingAsDone = function (email) { return axios.delete('/lead', { params: { email: email } }); };
export var createWebinarRdv = function (email, projectId, date) {
    axios.post('/new-ri/v1/webinars/care/participants/', { date: date, project_id: projectId }, { baseURL: '' });
    return axios.post('/ri/create-webinar-rdv', { email: email, date: date }, { baseURL: '' });
};
export var addLeadInvest = function (data) { return axios.post('/add_lead_invest', data); };
