import { jsx as _jsx } from "react/jsx-runtime";
import SearchBar from './SearchBar';
import SearchResults from './SearchResults';
import SearchManager from './SearchManager';
export default function Search() {
    return (_jsx(SearchBar, { children: function (_a) {
            var query = _a.query, type = _a.type;
            return (_jsx(SearchManager, { query: query, type: type, children: function (data) { return (_jsx(SearchResults, { data: data })); } }));
        } }));
}
