var CALL_STATUS = {
    'no-call-answer': {
        label: 'Ne répond pas',
        status: 'lndp',
        types: ['sdr', 'p2p', 'reputation', 'waiting_visio'],
        daily: 1,
        total: 1,
    },
    'rdv-done': {
        label: 'Rendez-vous terminé',
        status: 'ok',
        types: ['p2p'],
        daily: 1,
        total: 1,
    },
    'rdv-taken': {
        label: 'Rendez-vous pris',
        status: 'ok',
        types: ['sdr', 'waiting_visio'],
        daily: 1,
        total: 10,
    },
    'testimony-given': {
        label: 'Avis déposé',
        status: 'ok',
        types: ['reputation'],
        daily: 1,
        total: 1,
    },
    'meeting-scheduled': {
        label: 'Rendez-vous futur pris',
        status: 'on',
        types: ['sdr', 'p2p', 'reputation'],
        daily: 0,
        total: 10,
    },
    'call-later': {
        label: 'À rappeler ultérieurement',
        status: 'bmt',
        types: ['sdr'],
        daily: 10,
        total: 1,
    },
    'no-society': {
        label: 'Pas de société',
        status: 'off',
        types: ['sdr'],
        daily: 1,
        total: 1,
    },
    'not-interested': {
        label: 'Pas intéressé',
        status: 'off',
        types: ['sdr'],
        daily: 1,
        total: 1,
    },
    'wrong-number': {
        label: 'Faux numéro',
        status: 'off',
        types: ['sdr'],
        daily: 1,
        total: 1,
    },
    'asking-4-cut': {
        label: 'Demande l\'arrêt',
        status: 'off',
        types: ['p2p'],
        daily: 1,
        total: 1,
    },
    'negative-feeling': {
        label: 'Sentiment / Avis négatif',
        status: 'off',
        types: ['reputation'],
        daily: 1,
        total: 1,
    },
    'doesnt-want': {
        label: 'Ne souhaite pas laisser d\'avis',
        status: 'off',
        types: ['reputation'],
        daily: 1,
        total: 1,
    },
    'do-it-rdv-future': {
        label: 'Avis ultérieurement, RDV de confirmation',
        status: 'on',
        types: ['reputation'],
        daily: 1,
        total: 10,
    },
    'no-cash': {
        label: 'Pas de trésorerie',
        status: 'off',
        types: ['sdr'],
        daily: 1,
        total: 1,
    },
    other: {
        label: 'Autre',
        status: 'off',
        types: ['sdr', 'p2p', 'reputation', 'waiting_visio'],
        daily: 1,
        total: 1,
    },
};
export default CALL_STATUS;
