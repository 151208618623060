var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var initialState = { comments: [] };
function commentsReducer(state, _a) {
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case 'addComment':
            return __assign(__assign({}, state), { comments: __spreadArray(__spreadArray([], state.comments, true), [
                    payload,
                ], false) });
        case 'markAsResolved':
            return __assign(__assign({}, state), { comments: __spreadArray([], state.comments.map(function (comment) { return ((comment.id === payload)
                    ? __assign(__assign({}, comment), { isResolved: !comment.isResolved }) : comment); }), true) });
        default:
            return state;
    }
}
export default commentsReducer;
