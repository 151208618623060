// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aH3sHudNop72gnBT3qAI {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
  min-width: 200px;
  white-space: nowrap;
}

button:hover {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  cursor: pointer;
}

.C4yV8ggdnUC3wGnlbbFF {
  padding-left: 24px;
  font-size: 16px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.UxjndxpaMIaxSybllnO9 {
  position: absolute;
  min-width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  overflow: hidden;
  margin-top: 4px;
  z-index: 10;
  right: 0;
}

.O29bQ30UbP9TdPDYSGgO {
  position: relative;
}

.mEGh9y5QCEpTu7a_V68S {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/library/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0DAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mDAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,sCAAsC;EACtC,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["@value colors: \"../style/colors.css\";\n@value color-purple, color-text-subtle from colors;\n\n.button {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  border: 1px solid #e5e5e5;\n  box-sizing: border-box;\n  border-radius: 5px;\n  font-weight: 400;\n  font-size: 16px;\n  transition: 0.3s;\n  min-width: 200px;\n  white-space: nowrap;\n}\n\nbutton:hover {\n  border-color: color-purple;\n  cursor: pointer;\n}\n\n.icon {\n  padding-left: 24px;\n  font-size: 16px;\n  color: color-text-subtle;\n}\n\n.modal {\n  position: absolute;\n  min-width: 100%;\n  box-sizing: border-box;\n  background-color: white;\n  padding: 0;\n  border-radius: 5px;\n  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);\n  overflow: hidden;\n  margin-top: 4px;\n  z-index: 10;\n  right: 0;\n}\n\n.dropdown {\n  position: relative;\n}\n\n.center {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"button": `aH3sHudNop72gnBT3qAI`,
	"icon": `C4yV8ggdnUC3wGnlbbFF`,
	"modal": `UxjndxpaMIaxSybllnO9`,
	"dropdown": `O29bQ30UbP9TdPDYSGgO`,
	"center": `mEGh9y5QCEpTu7a_V68S`
};
export default ___CSS_LOADER_EXPORT___;
