import getErrorMessage from './error';
import { logError } from './logs';
export function fallbackCopyTextToClipboard(text, callback) {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log("Fallback: Copying text command was ".concat(msg));
        if (callback && successful) {
            callback();
        }
    }
    catch (err) {
        logError(err);
        console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
}
export function copyTextToClipboard(text, onSuccess, onError) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text, onSuccess);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        if (onSuccess)
            onSuccess();
    }, function (err) {
        if (onError)
            onError(getErrorMessage(err));
    });
}
