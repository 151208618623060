import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { CheckCircle, Paid, Person, PointOfSale, Search, } from '@mui/icons-material';
import InputText from 'components/library/InputText';
import Input from 'components/library/Input';
import Dropdown from 'components/library/Dropdown';
import DropdownItem from 'components/library/DropdownItem';
import Button from 'components/library/Button';
import AddInvestLead from 'components/AddInvestLead';
import layout from 'components/library/style/layout.module.css';
import styles from './SearchBar.module.css';
export default function SearchBar(_a) {
    var children = _a.children;
    var _b = useState({ query: '', type: 'all' }), filters = _b[0], setFilters = _b[1];
    var _c = useState(''), query = _c[0], setQuery = _c[1];
    var _d = useState('all'), type = _d[0], setType = _d[1];
    var handleSearch = function () {
        setFilters({ query: query, type: type });
    };
    var handleKeydownChange = function (e) {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };
    var handleQueryChange = function (e) {
        setQuery(e.target.value);
    };
    var handleTypeChange = function (value) {
        if (['kyc', 'ri', 'sales', 'all'].includes(value)) {
            setType(value);
        }
        else {
            setType('all');
        }
    };
    var reset = function () {
        setQuery('');
        setType('all');
        setFilters({ query: '', type: 'all' });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.searchbar, children: _jsxs("div", { className: "".concat(layout.container, " ").concat(layout.row, " ").concat(styles.container), children: [_jsxs("div", { className: "".concat(layout.row, " ").concat(styles.row), children: [_jsx(Input, { name: "customer", label: "Rechercher un client", className: styles.label, children: _jsx(InputText, { name: "customer", placeholder: "Rechercher par nom, email, num\u00E9ro de t\u00E9l\u00E9phone, projet...", className: "".concat(styles.input, " ").concat(styles.search), value: query, onChange: handleQueryChange, onKeyDown: handleKeydownChange, children: _jsx(Search, { className: styles.icon }) }) }), _jsx(Input, { name: "type", label: "Type de client", className: styles.label, children: _jsxs(Dropdown, { value: type, className: "".concat(styles.input, " ").concat(styles.dropdown), onChange: handleTypeChange, children: [_jsx(DropdownItem, { value: "all", icon: _jsx(Person, {}), children: "Tous les clients" }), _jsx(DropdownItem, { value: "sales", icon: _jsx(PointOfSale, {}), children: "Clients de la base sales" }), _jsx(DropdownItem, { value: "ri", icon: _jsx(Paid, {}), children: "Clients de la base RI" }), _jsx(DropdownItem, { value: "kyc", icon: _jsx(CheckCircle, {}), children: "Clients de la base KYC" })] }) }), _jsx(Button, { className: styles.submit, onClick: handleSearch, disabled: query === '', children: "Rechercher" }), (query !== '')
                                    && (_jsx(Button, { variant: "ghost", className: styles.submit, onClick: reset, children: "Annuler" }))] }), _jsx(AddInvestLead, {})] }) }), children(filters)] }));
}
