// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RbQDkvkVwASb5Hmi6MvV {
  padding: 0;
  justify-self: flex-start;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.kqp2oxzPALJQknQPYoIs {
  min-height: unset;
  min-width: unset;
  padding: 0;
}

.EEXywSCIIqYngPsCSLg1 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 18px !important;
  transition: 0.3s;
}

.EEXywSCIIqYngPsCSLg1:hover {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.EEXywSCIIqYngPsCSLg1.RmE9I2fb8dmkhhvHpQBg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.AeqVmmNgo4fncGjVAYDl {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.Ctio1KVaieJSvbybf1f4 p {
  font-size: 14px;
}

.Lh90zNnvBClXe88YyNvf {
  display: flex;
  flex-direction: row;
}

.ea77hFF7k3uS3DZkP2Gv span,
.ea77hFF7k3uS3DZkP2Gv svg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}

.GyxFkuS0ybRWh8L_BZDO {
  min-width: 250px;
}

.GyxFkuS0ybRWh8L_BZDO span {
  font-weight: 500;
  transition: 0.3s;
}

.GyxFkuS0ybRWh8L_BZDO:hover span,
.GyxFkuS0ybRWh8L_BZDO.RmE9I2fb8dmkhhvHpQBg span {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.GyxFkuS0ybRWh8L_BZDO.ea77hFF7k3uS3DZkP2Gv:hover span {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}

.DVFz2CDDerh1ZtpkeVJY {
  border-bottom: 1px solid #e4e4e4;
}

.DVFz2CDDerh1ZtpkeVJY:hover {
  border-color: #e4e4e4;
}
`, "",{"version":3,"sources":["webpack://./src/views/Search/SearchResults/SearchResultsData/SearchResultsDataRow/SearchResultsDataRow.module.css"],"names":[],"mappings":"AAGA;EACE,UAAU;EACV,wBAAwB;EACxB,mDAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,mDAAwB;EACxB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;;EAEE,mDAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;;EAEE,mDAAmB;AACrB;;AAEA;EACE,mDAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-purple, color-error from colors;\n\n.bookmark {\n  padding: 0;\n  justify-self: flex-start;\n  color: color-text-subtle;\n}\n\n.button {\n  min-height: unset;\n  min-width: unset;\n  padding: 0;\n}\n\n.icon {\n  color: color-text-subtle;\n  font-size: 18px !important;\n  transition: 0.3s;\n}\n\n.icon:hover {\n  color: color-purple;\n}\n\n.icon.active {\n  color: color-purple;\n}\n\n.customer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n}\n\n.row p {\n  font-size: 14px;\n}\n\n.actions {\n  display: flex;\n  flex-direction: row;\n}\n\n.warning span,\n.warning svg {\n  color: color-error;\n}\n\n.item {\n  min-width: 250px;\n}\n\n.item span {\n  font-weight: 500;\n  transition: 0.3s;\n}\n\n.item:hover span,\n.item.active span {\n  color: color-purple;\n}\n\n.item.warning:hover span {\n  color: color-error;\n}\n\n.meeting {\n  border-bottom: 1px solid #e4e4e4;\n}\n\n.meeting:hover {\n  border-color: #e4e4e4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"bookmark": `RbQDkvkVwASb5Hmi6MvV`,
	"button": `kqp2oxzPALJQknQPYoIs`,
	"icon": `EEXywSCIIqYngPsCSLg1`,
	"active": `RmE9I2fb8dmkhhvHpQBg`,
	"customer": `AeqVmmNgo4fncGjVAYDl`,
	"row": `Ctio1KVaieJSvbybf1f4`,
	"actions": `Lh90zNnvBClXe88YyNvf`,
	"warning": `ea77hFF7k3uS3DZkP2Gv`,
	"item": `GyxFkuS0ybRWh8L_BZDO`,
	"meeting": `DVFz2CDDerh1ZtpkeVJY`
};
export default ___CSS_LOADER_EXPORT___;
