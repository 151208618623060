import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import layout from 'components/library/style/layout.module.css';
import CustomerDataMainProject from './CustomerDataMainProject';
import CustomerDataMainComment from './CustomerDataMainComment';
import CustomerDataMainMeetings from './CustomerDataMainMeetings';
import CustomerDataMainProjectsLinked from './CustomerDataMainProjectsLinked';
import CustomerDataMainCallRecord from './CustomerDataMainCallRecord';
import CustomerDataMainActivity from './CustomerDataMainActivity';
import styles from './CustomerDataMain.module.css';
export default function CustomerDataMain(_a) {
    var customer = _a.customer;
    return (_jsx("main", { className: "".concat(layout.container, " ").concat(layout.section), children: _jsxs("div", { className: styles.grid, children: [(customer.p2p)
                    && (_jsx(CustomerDataMainProject, { customer: customer, project: customer.p2p })), (customer.comments)
                    && (_jsx(CustomerDataMainComment, { comments: customer.comments, id: customer.id })), (customer.meetings)
                    && (_jsx(CustomerDataMainMeetings, { meetings: customer.meetings })), (customer.phone)
                    && (_jsx(CustomerDataMainCallRecord, { phone: customer.phone })), _jsxs("div", { children: [(customer.projects)
                            && (_jsx(CustomerDataMainProjectsLinked, { projects: customer.projects, customer: customer })), (customer.lastHistory)
                            && (_jsx(CustomerDataMainActivity, { history: customer.lastHistory }))] })] }) }));
}
