var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Tooltip, styled, tooltipClasses, } from '@mui/material';
import { copyTextToClipboard } from 'utils/copyToClipboard';
import * as statuses from 'services/store/states';
import styles from './CopyText.module.css';
var StyledTooltip = styled(function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    _jsx(Tooltip, __assign({}, props, { classes: { popper: className } })));
})(function () {
    var _a;
    return (_a = {},
        _a["& .".concat(tooltipClasses.arrow)] = {
            color: '#535353',
        },
        _a["& .".concat(tooltipClasses.tooltip)] = {
            backgroundColor: '#535353',
            color: '#ffffff',
            fontSize: 11,
            fontStyle: 'italic',
            padding: '8px',
        },
        _a);
});
export default function CopyText(_a) {
    var label = _a.label;
    var _b = useState(statuses.IDLE), status = _b[0], setStatus = _b[1];
    var copyInClipboard = function () {
        copyTextToClipboard(label, function () {
            setStatus(statuses.SUCCESS);
            window.setTimeout(function () { return setStatus(statuses.IDLE); }, 1000);
        });
    };
    return (_jsx(StyledTooltip, { title: (status === statuses.SUCCESS)
            ? 'Copié !'
            : 'Cliquer pour copier dans votre presse papier', arrow: true, children: _jsx("button", { type: "button", className: styles.copy, onClick: copyInClipboard, children: label }) }));
}
