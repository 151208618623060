// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qKKybzFztRVrxQdZPqJ4 {
  display: grid;
  grid-template:
    "a a b" auto
    "a a c" auto
    "record record d" auto / 2fr 2fr 3fr;
  gap: 24px;
  padding-bottom: 24px;
}

@media screen and (width <= 768px) {
  .qKKybzFztRVrxQdZPqJ4 {
    grid-template:
      "b" auto
      "a" auto
      "c" auto
      "d" auto
      "record" auto / 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerData/CustomerDataMain/CustomerDataMain.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;wCAGsC;EACtC,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE;IACE;;;;;yBAKqB;EACvB;AACF","sourcesContent":[".grid {\n  display: grid;\n  grid-template:\n    \"a a b\" auto\n    \"a a c\" auto\n    \"record record d\" auto / 2fr 2fr 3fr;\n  gap: 24px;\n  padding-bottom: 24px;\n}\n\n@media screen and (width <= 768px) {\n  .grid {\n    grid-template:\n      \"b\" auto\n      \"a\" auto\n      \"c\" auto\n      \"d\" auto\n      \"record\" auto / 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `qKKybzFztRVrxQdZPqJ4`
};
export default ___CSS_LOADER_EXPORT___;
