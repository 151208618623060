import * as types from './types';
import toastFactory from './factory';
export function addToast(properties) {
    return function (dispatch) {
        var toast = toastFactory(properties);
        dispatch({ type: types.ADD_TOAST, payload: toast });
    };
}
export function removeToast(id) {
    return function (dispatch) {
        dispatch({ type: types.REMOVE_TOAST, payload: id });
    };
}
