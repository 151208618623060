import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import useOutsideClickAlert from 'hooks/useOutsideClick';
import styles from './Dropdown.module.css';
export default function Dropdown(_a) {
    var value = _a.value, className = _a.className, icon = _a.icon, _b = _a.isDropdownIconDisabled, isDropdownIconDisabled = _b === void 0 ? false : _b, _c = _a.shouldCloseAfterClick, shouldCloseAfterClick = _c === void 0 ? true : _c, onChange = _a.onChange, children = _a.children;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var modal = useRef(null);
    useOutsideClickAlert(modal, function () { return isOpen && setIsOpen(false); });
    var handleClick = function () { return setIsOpen(function (state) { return !state; }); };
    var handleDropdownItemClick = function (e) {
        var currentValue = e.target.dataset.value;
        var close = function () { return setIsOpen(false); };
        if (currentValue) {
            onChange(currentValue, close);
            if (shouldCloseAfterClick)
                close();
            return;
        }
        var parentValue = e.target.parentElement.dataset.value;
        if (parentValue) {
            onChange(parentValue, close);
            if (shouldCloseAfterClick)
                close();
        }
    };
    return (_jsxs("div", { ref: modal, className: styles.dropdown, children: [_jsxs("button", { type: "button", onClick: handleClick, className: "".concat(!isDropdownIconDisabled ? styles.button : '', " ").concat(className), children: [value, _jsxs("div", { className: styles.center, children: [(!isDropdownIconDisabled)
                                && ((isOpen)
                                    ? _jsx(ExpandLess, { className: styles.icon })
                                    : _jsx(ExpandMore, { className: styles.icon })), icon] })] }), (isOpen)
                && (_jsx("div", { className: styles.modal, tabIndex: -1, role: "button", onClick: handleDropdownItemClick, onKeyDown: handleDropdownItemClick, children: children }))] }));
}
