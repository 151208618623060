// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CN0XXE4MaDsnrj1JZbGd {
  border-bottom: 1px solid #e4e4e4;
  padding: 12px 0;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
}

.CN0XXE4MaDsnrj1JZbGd p {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.Y87EDn39r2orpBjZfIFd {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 14px;
  margin-left: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Search/SearchResults/SearchResultsTitle/SearchResultsTitle.module.css"],"names":[],"mappings":"AAGA;EACE,gCAAgC;EAChC,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,mDAAwB;EACxB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n.title {\n  border-bottom: 1px solid #e4e4e4;\n  padding: 12px 0;\n  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);\n}\n\n.title p {\n  font-weight: 500;\n  font-size: 14px;\n  margin: 0;\n}\n\n.hint {\n  color: color-text-subtle;\n  font-size: 14px;\n  margin-left: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"title": `CN0XXE4MaDsnrj1JZbGd`,
	"hint": `Y87EDn39r2orpBjZfIFd`
};
export default ___CSS_LOADER_EXPORT___;
