import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from 'components/library/Button';
import styles from '../LeadsTunnelCustomerCall.module.css';
import STATUS from '../utils';
export default function CallEndReasons(_a) {
    var _b;
    var reason = _a.reason, selectReason = _a.selectReason, options = _a.options, other = _a.other, onOtherChange = _a.onOtherChange, submit = _a.submit, close = _a.close;
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: styles.title, children: "Pourquoi mettez-vous fin \u00E0 l'appel ?" }), _jsx("select", { value: reason, onChange: selectReason, className: styles.select, children: options.map(function (option) { return (_jsx("option", { value: option.key, children: option.label })); }) }), (reason === 'other')
                && (_jsxs("label", { htmlFor: "explain", className: styles.input, children: ["Merci de pr\u00E9ciser la raison :", _jsx("input", { id: "explain", type: "text", value: other, onChange: onOtherChange })] })), _jsx("p", { className: styles.status, children: "status: ".concat(((_b = STATUS[reason]) === null || _b === void 0 ? void 0 : _b.status) || 'off') }), _jsx(Button, { className: styles.submit, onClick: submit, children: "Valider" }), _jsx(Button, { variant: "ghost", onClick: close, className: styles.cancel, children: "Annuler" })] }));
}
