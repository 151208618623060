import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import CustomerData from './CustomerData';
export default function Customer() {
    var _a = useParams(), email = _a.email, idAyomi = _a.idAyomi;
    if (!email || !idAyomi) {
        return (_jsx("p", { children: "Aucun client trouv\u00E9" }));
    }
    return (_jsx(CustomerData, { email: email, idAyomi: idAyomi }));
}
