// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bq5LUFpjWiDj626Id295 {
  position: fixed;
  inset: 0 0 auto;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 75%);
}

.ZvI_CmRiOjPvyCkeJTiW {
  background-color: white;
  max-width: 320px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 4px 25px rgb(0 0 0 / 5%);
  text-align: center;
}

.OrT9Q5_bRgJvEuKCz3VO {
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}

.zFWBU0N0H2QAOcE3MHyd {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.GW4vyrTvF6Cy0G0vu3W1 {
  width: 100%;
  background-color: #f8f8f8;
  color: #4b4b4b;
  font-weight: 400;
  border: 1px solid #e5e5e5;
}

.GW4vyrTvF6Cy0G0vu3W1:hover {
  background-color: #e5e5e5;
  color: black;
  border-color: #e5e5e5;
}

.ndU07AsUzNAk2hzAr6g9 {
  display: none;
}

.Bh0r_IXmKLEX6X8myk4R {
  height: 42px;
  margin-bottom: 16px;
}

.A4rlzNFB4XD4c4RJLQM4 {
  width: 100%;
}

.a15XIhi1ZqAn2gBtLi0U {
  background-color: #f8f8f8;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  padding: 0.5rem;
  border-radius: 5px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/views/Leads/LeadsToolbar/LeadsToolbarUpload/LeadsToolbarUpload.module.css"],"names":[],"mappings":"AAGA;EACE,eAAe;EACf,eAAe;EACf,aAAa;EACb,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,mDAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-gray-light from colors;\n\n.background {\n  position: fixed;\n  inset: 0 0 auto;\n  z-index: 1000;\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(255 255 255 / 75%);\n}\n\n.modal {\n  background-color: white;\n  max-width: 320px;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-radius: 5px;\n  box-shadow: 0 4px 25px rgb(0 0 0 / 5%);\n  text-align: center;\n}\n\n.status {\n  font-weight: 500;\n  font-size: 20px;\n  margin: 0;\n}\n\n.description {\n  color: color-text-subtle;\n}\n\n.discrete {\n  width: 100%;\n  background-color: #f8f8f8;\n  color: #4b4b4b;\n  font-weight: 400;\n  border: 1px solid #e5e5e5;\n}\n\n.discrete:hover {\n  background-color: #e5e5e5;\n  color: black;\n  border-color: #e5e5e5;\n}\n\n.hide {\n  display: none;\n}\n\n.dropdown {\n  height: 42px;\n  margin-bottom: 16px;\n}\n\n.import {\n  width: 100%;\n}\n\n.success-message {\n  background-color: #f8f8f8;\n  color: color-text-subtle;\n  padding: 0.5rem;\n  border-radius: 5px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-gray-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray-light"]}`,
	"background": `bq5LUFpjWiDj626Id295`,
	"modal": `ZvI_CmRiOjPvyCkeJTiW`,
	"status": `OrT9Q5_bRgJvEuKCz3VO`,
	"description": `zFWBU0N0H2QAOcE3MHyd`,
	"discrete": `GW4vyrTvF6Cy0G0vu3W1`,
	"hide": `ndU07AsUzNAk2hzAr6g9`,
	"dropdown": `Bh0r_IXmKLEX6X8myk4R`,
	"import": `A4rlzNFB4XD4c4RJLQM4`,
	"success-message": `a15XIhi1ZqAn2gBtLi0U`
};
export default ___CSS_LOADER_EXPORT___;
