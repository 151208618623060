// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lxmZsXQrxHirjUnSdM9m {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 42px;
  padding: 0 8px;
}

.w1qwfa4Zoz4rDBN06_f2 {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.lxmZsXQrxHirjUnSdM9m svg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  font-size: 16px !important;
}

.lxmZsXQrxHirjUnSdM9m:hover {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/library/DropdownItem/DropdownItem.module.css"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,mDAAwB;AAC1B;;AAEA;EACE,mDAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,8DAAiC;AACnC","sourcesContent":["@value colors: \"../style/colors.css\";\n@value color-text-subtle, color-purple, color-purple-bg from colors;\n\n.item {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n  width: 100%;\n  height: 42px;\n  padding: 0 8px;\n}\n\n.label {\n  text-align: left;\n  font-size: 14px;\n  font-weight: 400;\n  color: color-text-subtle;\n}\n\n.item svg {\n  color: color-purple;\n  font-size: 16px !important;\n}\n\n.item:hover {\n  background-color: color-purple-bg;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"item": `lxmZsXQrxHirjUnSdM9m`,
	"label": `w1qwfa4Zoz4rDBN06_f2`
};
export default ___CSS_LOADER_EXPORT___;
