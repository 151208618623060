import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Logout } from '@mui/icons-material';
import layout from 'components/library/style/layout.module.css';
import styles from './Navigation.module.css';
export default function Navigation(_a) {
    var email = _a.email;
    var logout = function () {
        window.localStorage.removeItem('auth_refresh');
        window.sessionStorage.removeItem('auth_token');
        window.location.assign("https://".concat(process.env.REACT_APP_DEBUG ? 'dev-' : '', "auth.ayomi.fr/disconnect"));
    };
    return (_jsx("nav", { className: styles.container, children: _jsxs("div", { className: "".concat(layout.container, " ").concat(styles.navigation), children: [_jsxs("div", { className: "".concat(layout.row, " ").concat(styles.group), children: [_jsx("div", { children: _jsxs("p", { className: "".concat(layout.row, " ").concat(styles.content), children: [_jsx("span", { className: styles.logo, children: "AYOMI" }), _jsx("span", { className: styles.point }), _jsx("span", { className: styles.soft, children: "Care" })] }) }), _jsx(NavLink, { to: "/search", className: function (_a) {
                                var isActive = _a.isActive;
                                return "".concat(styles.link, " ").concat(isActive ? styles.active : styles.inactive);
                            }, children: "Clients" }), _jsx(NavLink, { to: "/leads", className: function (_a) {
                                var isActive = _a.isActive;
                                return "".concat(styles.link, " ").concat(isActive ? styles.active : styles.inactive);
                            }, children: "Tunnel" })] }), _jsxs("div", { className: "".concat(layout.row, " ").concat(styles.session), children: [_jsxs("p", { className: styles.user, children: ["Connect\u00E9 en tant que", _jsx("span", { children: email })] }), _jsx(Tooltip, { title: "Se d\u00E9connecter", placement: "bottom", children: _jsx("button", { type: "button", "aria-label": "logout", onClick: logout, children: _jsx(Logout, { className: styles.icon }) }) })] })] }) }));
}
