// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  outline: none;
}

a {
  color: black;
  text-decoration: underline;
}

label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  row-gap: 0.5rem;
}

input {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/library/style/reset.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,SAAS;EACT,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n}\n\nbutton {\n  background: none;\n  border: none;\n  color: inherit;\n  cursor: pointer;\n  margin: 0;\n  outline: none;\n}\n\na {\n  color: black;\n  text-decoration: underline;\n}\n\nlabel {\n  display: flex;\n  flex-direction: column;\n  font-weight: 500;\n  row-gap: 0.5rem;\n}\n\ninput {\n  border: 1px solid #ccc;\n  border-radius: 0.25rem;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
