var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from '@mui/material';
import { Error } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from 'components/library/Button';
import * as statuses from 'services/store/states';
import denyLead from 'services/network/api/ri';
import { addToast } from 'services/store/features/toasts/actions';
import { toCamel } from 'utils/converter';
import modal from '../../CustomerDataHeader.module.css';
import styles from './ActionsInvestDenial.module.css';
import { logError } from 'utils/logs';
export default function ActionsInvestDenial(_a) {
    var _this = this;
    var iss = _a.iss;
    var _b = useState(statuses.IDLE), status = _b[0], setStatus = _b[1];
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useParams(), email = _d.email, idAyomi = _d.idAyomi;
    var dispatch = useDispatch();
    var _e = useState({
        reasonNoInvest: 'not_interested',
        commentP2P: '',
    }), _f = _e[0], reasonNoInvest = _f.reasonNoInvest, commentP2P = _f.commentP2P, setForm = _e[1];
    var confirm = function () { return __awaiter(_this, void 0, void 0, function () {
        var formData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!reasonNoInvest || !email || !idAyomi)
                        return [2 /*return*/];
                    setStatus(statuses.LOADING);
                    formData = new FormData();
                    formData.append('reasonNoInvest', toCamel(reasonNoInvest));
                    formData.append('commentP2P', commentP2P);
                    formData.append('iss', iss.toString());
                    formData.append('email', email);
                    formData.append('id_ayomi', idAyomi);
                    formData.append('fonction', 'noInvest');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, denyLead(formData)];
                case 2:
                    _a.sent();
                    setStatus(statuses.SUCCESS);
                    setIsOpen(false);
                    dispatch(addToast({
                        type: 'success',
                        title: 'Lead investisseur mis en démenti avec succès',
                        description: '',
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    setStatus(statuses.FAIL);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { className: styles.button, onClick: function () { return setIsOpen(true); }, children: "Passer l'investisseur en d\u00E9menti" }), isOpen
                && (_jsx(Modal, { open: isOpen, className: modal['modal-container'], children: _jsxs("div", { className: modal.modal, children: [_jsx("h2", { className: styles.title, children: "\u00CAtes vous s\u00FBr de vouloir passer cet investisseur en d\u00E9menti ?" }), (status === statuses.FAIL)
                                && (_jsxs("p", { className: styles.error, children: [_jsx(Error, {}), "Une erreur est survenue, veuillez r\u00E9essayer"] })), _jsxs("form", { className: styles.form, children: [_jsxs("label", { htmlFor: "reason", children: ["Raison de non investissement", _jsxs("select", { value: reasonNoInvest, onChange: function (e) { return setForm(function (curr) { return (__assign(__assign({}, curr), { reasonNoInvest: e.target.value })); }); }, children: [_jsx("option", { value: "not_informed", children: "Non inform\u00E9" }), _jsx("option", { value: "no_connection", children: "Pas de connection" }), _jsx("option", { value: "no_cash", children: "Pas d'argent" }), _jsx("option", { value: "not_an_invest", children: "Pas un investisseur" }), _jsx("option", { value: "not_trust_team", children: "Ne crois pas en l'\u00E9quipe" }), _jsx("option", { value: "not_believe_market", children: "Ne crois pas dans le march\u00E9" }), _jsx("option", { value: "not_believe_product", children: "Ne crois pas dans le produit" }), _jsx("option", { value: "not_believe_project", children: "Ne crois pas dans le projet" }), _jsx("option", { value: "not_interested", children: "Pas int\u00E9ress\u00E9" }), _jsx("option", { value: "valo_too_high", children: "Valorisation trop \u00E9lev\u00E9e" }), _jsx("option", { value: "no_intention", children: "Pas d'intention" }), _jsx("option", { value: "already_invested", children: "D\u00E9j\u00E0 investi" })] })] }), _jsxs("label", { htmlFor: "commentP2P", children: ["Commentaire (visible par le P2P)", _jsx("textarea", { value: commentP2P, onChange: function (e) {
                                                    setForm(function (curr) { return (__assign(__assign({}, curr), { commentP2P: e.target.value })); });
                                                } })] })] }), _jsxs("div", { className: styles.row, children: [_jsx(Button, { variant: "ghost", onClick: function () { return setIsOpen(false); }, disabled: status === statuses.LOADING, children: "Annuler" }), _jsx(Button, { onClick: confirm, disabled: !reasonNoInvest || status === statuses.LOADING, children: status === statuses.LOADING
                                            ? _jsx("span", { className: "spinner white" })
                                            : 'Confirmer' })] })] }) }))] }));
}
