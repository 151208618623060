import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from 'react';
import { AppRegistration, Call, EventAvailable, Mail, Phone, TagSharp, } from '@mui/icons-material';
import Button from 'components/library/Button';
import { createLog, setPastMeetingAsDone } from 'services/network/api/leads';
import layout from 'components/library/style/layout.module.css';
import { LEAD_TYPES } from 'constants/leads';
import LeadsTunnelCustomerCall from './LeadsTunnelCustomerCall';
import styles from './LeadsTunnelCustomer.module.css';
import LeadsTunnelCustomerComment from './LeadsTunnelCustomerComment';
export default function LeadsTunnelCustomer(_a) {
    var lead = _a.lead, getNewLead = _a.getNewLead, setLead = _a.setLead;
    var _b = useState(false), isCalling = _b[0], setIsCalling = _b[1];
    var _c = useState(null), ringover = _c[0], setRingover = _c[1];
    var target = useRef(null);
    var onCall = function (e) {
        e.currentTarget.disabled = true;
        createLog(lead.id, 'call');
        if (lead.meeting === 'calendrier') {
            setPastMeetingAsDone(lead.email);
        }
        if (ringover) {
            ringover.click();
        }
        setIsCalling(true);
    };
    useEffect(function () {
        if (target.current) {
            var callback = function (change) {
                var _a;
                var ringoverComponent = (_a = change.find(function (record) { return record.type === 'childList'; })) === null || _a === void 0 ? void 0 : _a.addedNodes[0];
                var number = ringoverComponent === null || ringoverComponent === void 0 ? void 0 : ringoverComponent.lastChild;
                if (number) {
                    setRingover(number);
                }
            };
            var observer_1 = new MutationObserver(callback);
            observer_1.observe(target.current, {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
            });
            return function () { return observer_1.disconnect(); };
        }
        return undefined;
    }, []);
    var onCallEnd = function () { return getNewLead({}); };
    return (_jsxs("section", { className: styles.tunnel, children: [_jsx("header", { className: styles.header, children: _jsxs("div", { className: layout.container, children: [_jsxs("div", { className: styles.row, children: [_jsxs("h2", { className: styles.title, children: [lead.name, _jsx("span", { className: "".concat(styles.pill, " ").concat(styles[lead.type]), children: LEAD_TYPES[lead.type] })] }), (!isCalling)
                                    && (_jsxs(Button, { onClick: onCall, className: styles.phone, children: [_jsx(Call, { className: styles.icon }), "Lancer l'appel"] }))] }), _jsxs("div", { className: styles.grid, children: [_jsxs("p", { className: styles.label, children: [_jsx(Phone, { className: styles.icon }), "T\u00E9l\u00E9phone"] }), _jsx("p", { ref: target, onClickCapture: onCall, children: lead.phone }), _jsxs("p", { className: styles.label, children: [_jsx(Mail, { className: styles.icon }), "Email"] }), _jsx("p", { children: lead.email }), _jsxs("p", { className: styles.label, children: [_jsx(AppRegistration, { className: styles.icon }), "Date"] }), _jsx("p", { children: lead.date }), _jsxs("p", { className: styles.label, children: [_jsx(TagSharp, { className: styles.icon }), "ID"] }), _jsx("p", { children: lead.id }), _jsxs("p", { className: styles.label, children: [_jsx(EventAvailable, { className: styles.icon }), "Type de rendez-vous"] }), _jsx("p", { className: "".concat(styles.pill, " ").concat(styles[lead.meeting]), children: lead.meeting })] }), (isCalling)
                            && (_jsx(LeadsTunnelCustomerCall, { lead: lead, onCallEnd: onCallEnd }))] }) }), _jsx("main", { className: layout.container, children: _jsx(LeadsTunnelCustomerComment, { lead: lead, setLead: setLead }) })] }));
}
