// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TLIZBn77SxpSU6bptdIE {
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem;
}

.YIWm9xPCpPx1q4G_kUR8 {
  justify-content: space-between;
  align-items: center;
}

._bENkQIWqxe9BxIyTldd {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
}

.Mk0ViIY4o439hqQbT2ld {
  display: none;
}

.NTm_Hyf1KJzDl81RFvUw {
  margin-right: 8px;
}

.YNmusqTC183wYaIi7pOB {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/views/Leads/LeadsToolbar/LeadsToolbar.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".toolbar {\n  border-bottom: 1px solid #e5e5e5;\n  padding: 1rem;\n}\n\n.leads {\n  justify-content: space-between;\n  align-items: center;\n}\n\n.title {\n  font-family: Roboto, sans-serif;\n  font-size: 1.5rem;\n  margin: 0;\n  font-weight: 500;\n}\n\n.hide {\n  display: none;\n}\n\n.icon {\n  margin-right: 8px;\n}\n\n.button-group {\n  display: flex;\n  flex-direction: row;\n  column-gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": `TLIZBn77SxpSU6bptdIE`,
	"leads": `YIWm9xPCpPx1q4G_kUR8`,
	"title": `_bENkQIWqxe9BxIyTldd`,
	"hide": `Mk0ViIY4o439hqQbT2ld`,
	"icon": `NTm_Hyf1KJzDl81RFvUw`,
	"button-group": `YNmusqTC183wYaIi7pOB`
};
export default ___CSS_LOADER_EXPORT___;
