import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import layout from 'components/library/style/layout.module.css';
import styles from './LeadsToolbar.module.css';
import LeadsToolbarUpload from './LeadsToolbarUpload';
import LeadsToolbarSearch from './LeadsToolbarSearch';
export default function LeadsToolbar(_a) {
    var children = _a.children;
    var _b = useState(), selectedLead = _b[0], setSelectedLead = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.toolbar, children: _jsxs("div", { className: "".concat(layout.container, " ").concat(layout.row, " ").concat(styles.leads), children: [_jsx("h1", { className: styles.title, children: "Tunnel" }), _jsxs("div", { className: styles['button-group'], children: [_jsx(LeadsToolbarSearch, { onSelect: setSelectedLead }), _jsx(LeadsToolbarUpload, {})] })] }) }), children({ lead: selectedLead })] }));
}
