// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ssphcofhOZy9j0em4EY8 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
}

.FArNcMU0c9a4Ai_wSub3 {
  border-right: 1px solid #e4e4e4;
  padding-right: 16px;
}

.L8_vz7fnS7cd7BnYtegk {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.d9HxnMYtM7783W1e7GLw,
.p47Svs3Oa_lae7aUdkbw {
  font-size: 14px;
  margin: 0;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.lmJJlDot5C4kJb5z3Pk4 {
  font-size: 14px;
  margin: 0;
}

.wURPnuYNvHpniA8chJss {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.zTEElylToPknKmsXQZHs {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-weight: 500;
  margin-bottom: 4px;
}

.ZUejDDIWhu3Y2rvBhlKX {
  opacity: 0.50;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerData/CustomerDataMain/CustomerDataMainMeetings/CustomerDataMainMeetings.module.css"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;EAEE,eAAe;EACf,SAAS;EACT,mDAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mDAAwB;EACxB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n.meeting {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  padding: 16px;\n  border-radius: 4px;\n  border: 1px solid #e4e4e4;\n}\n\n.time {\n  border-right: 1px solid #e4e4e4;\n  padding-right: 16px;\n}\n\n.date {\n  margin: 0;\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n}\n\n.hour,\n.with {\n  font-size: 14px;\n  margin: 0;\n  color: color-text-subtle;\n}\n\n.value {\n  font-size: 14px;\n  margin: 0;\n}\n\n.title {\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  margin-bottom: 0;\n}\n\n.subtitle {\n  color: color-text-subtle;\n  font-weight: 500;\n  margin-bottom: 4px;\n}\n\n.archived {\n  opacity: 0.50;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"meeting": `ssphcofhOZy9j0em4EY8`,
	"time": `FArNcMU0c9a4Ai_wSub3`,
	"date": `L8_vz7fnS7cd7BnYtegk`,
	"hour": `d9HxnMYtM7783W1e7GLw`,
	"with": `p47Svs3Oa_lae7aUdkbw`,
	"value": `lmJJlDot5C4kJb5z3Pk4`,
	"title": `wURPnuYNvHpniA8chJss`,
	"subtitle": `zTEElylToPknKmsXQZHs`,
	"archived": `ZUejDDIWhu3Y2rvBhlKX`
};
export default ___CSS_LOADER_EXPORT___;
