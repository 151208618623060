var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var getLastProject = function (projects) {
    if (projects && projects.length > 0) {
        var sortedProjects = __spreadArray([], projects, true);
        sortedProjects.sort(function (a, b) { return new Date(b.lastCall).getTime() - new Date(a.lastCall).getTime(); });
        return sortedProjects[0];
    }
    return undefined;
};
export var getCalendarUrl = function (calendar, customer) { return "".concat(calendar, "?source=CARE&name=").concat(customer.firstName, "&surname=").concat(customer.lastName, "&phone=").concat(customer.phone, "&email=").concat(customer.email); };
export var getCalendarUrlRI = function (calendar, customer) { return "".concat(calendar).concat(customer.idAyomi, "?source=CARE&name=").concat(customer.firstName, "&surname=").concat(customer.lastName, "&phone=").concat(customer.phone, "&email=").concat(customer.email); };
