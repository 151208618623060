// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FESWFHTy7CQf3YurTUH8 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-align: left;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/CopyText/CopyText.module.css"],"names":[],"mappings":"AAGA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,gBAAgB;EAChB,mDAAmB;EACnB,UAAU;AACZ","sourcesContent":["@value colors: \"../library/style/colors.css\";\n@value color-purple from colors;\n\n.copy {\n  font-family: Roboto, sans-serif;\n  font-size: 14px;\n  font-weight: 400;\n  text-decoration: underline;\n  text-align: left;\n  color: color-purple;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"copy": `FESWFHTy7CQf3YurTUH8`
};
export default ___CSS_LOADER_EXPORT___;
