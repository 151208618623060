var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Bookmark, BookmarkBorder, CheckCircle, Event, Loop, MoreHoriz, NavigateBefore, Visibility, } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import layout from 'components/library/style/layout.module.css';
import CopyText from 'components/CopyText';
import Dropdown from 'components/library/Dropdown';
import DropdownItem from 'components/library/DropdownItem';
import MeetingDropdownList from 'components/MeetingDropdownList';
import getErrorMessage from 'utils/error';
import { addToast } from 'services/store/features/toasts/actions';
import { createCustomer } from 'services/network/api/customers';
import { getCalendarUrl } from 'services/controller/customers';
import { handleAccountConfirmation, handleCustomerBookmarked, handleResetPassword, } from 'utils/auth';
import grid from '../../SearchResults.module.css';
import styles from './SearchResultsDataRow.module.css';
import { logError } from 'utils/logs';
export default function SearchResultsDataRow(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g;
    var customer = _a.customer;
    var _h = useState(customer.id), id = _h[0], setId = _h[1];
    var _j = useState(false), isMeetingSelected = _j[0], setIsMeetingSelected = _j[1];
    var _k = useState(customer.isBookmarked), isBookmarked = _k[0], setIsBookmarked = _k[1];
    var dispatch = useDispatch();
    var createUser = function (callback) { return __awaiter(_this, void 0, void 0, function () {
        var newUser, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newUser = {
                        email: customer.email,
                        id_ayomi: customer.idAyomi,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createCustomer(newUser)];
                case 2:
                    data = (_a.sent()).data;
                    setId(data.id);
                    if (data.id) {
                        if (callback)
                            callback(data.id);
                    }
                    else {
                        dispatch(addToast({
                            type: 'error',
                            title: 'Impossible de créer l\'utilisateur',
                            description: (data.error) ? getErrorMessage(data.error) : 'No ID found / created',
                        }));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de créer l\'utilisateur',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleBookmarked = function () { return __awaiter(_this, void 0, void 0, function () {
        var launchBookmarked;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    launchBookmarked = function () { return handleCustomerBookmarked(customer.email, !isBookmarked, customer.idAyomi, dispatch, setIsBookmarked); };
                    if (!!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, createUser(launchBookmarked)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, launchBookmarked()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handlePassword = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleResetPassword(customer.email, dispatch)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var sendAccountConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleAccountConfirmation(customer.email, dispatch)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (value, close) { return __awaiter(_this, void 0, void 0, function () {
        var calendar;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (value === 'meeting') {
                        setIsMeetingSelected(function (state) { return !state; });
                        return [2 /*return*/];
                    }
                    if (!(value === 'account')) return [3 /*break*/, 2];
                    return [4 /*yield*/, sendAccountConfirmation()];
                case 1:
                    _c.sent();
                    _c.label = 2;
                case 2:
                    if (!(value === 'password')) return [3 /*break*/, 4];
                    return [4 /*yield*/, handlePassword()];
                case 3:
                    _c.sent();
                    _c.label = 4;
                case 4:
                    if (['sales', 'legal', 'ri', 'closing', 'marketing'].includes(value)) {
                        calendar = (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.p2p) === null || _a === void 0 ? void 0 : _a.inCharge[value]) === null || _b === void 0 ? void 0 : _b.calendar;
                        if (calendar) {
                            window.open(getCalendarUrl(calendar, customer));
                        }
                    }
                    setIsMeetingSelected(false);
                    if (close)
                        close();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: grid.background, children: _jsxs("div", { className: "".concat(grid.row, " ").concat(layout.container, " ").concat(styles.row), children: [_jsxs("div", { className: styles.customer, children: [_jsx("button", { type: "button", className: styles.bookmark, onClick: handleBookmarked, children: (isBookmarked)
                                ? (_jsx(Bookmark, { className: "".concat(styles.icon, " ").concat(styles.active) }))
                                : (_jsx(BookmarkBorder, { className: styles.icon })) }), _jsx("p", { children: customer.username })] }), _jsx(CopyText, { label: customer.email }), _jsx(CopyText, { label: customer.phone && customer.phone.includes('/') ? customer.phone.split('/')[0] : customer.phone }), _jsx("p", { className: layout.desktop, children: ((_b = customer === null || customer === void 0 ? void 0 : customer.p2p) === null || _b === void 0 ? void 0 : _b.name) || 'N/A' }), _jsx("p", { className: layout.desktop, children: (customer === null || customer === void 0 ? void 0 : customer.type.join(', ')) || 'N/A' }), _jsx("p", { className: layout.desktop, children: ((_c = customer === null || customer === void 0 ? void 0 : customer.p2p) === null || _c === void 0 ? void 0 : _c.status) || 'N/A' }), _jsx("p", { className: layout.desktop, children: ((_e = (_d = customer === null || customer === void 0 ? void 0 : customer.meetings) === null || _d === void 0 ? void 0 : _d.archived[0]) === null || _e === void 0 ? void 0 : _e.date) || 'N/A' }), _jsxs("div", { className: styles.actions, children: [_jsx(NavLink, { to: "/search/".concat(customer.idAyomi, "/").concat(customer.email), children: _jsx(Visibility, { className: styles.icon }) }), _jsx(Dropdown, { icon: _jsx(MoreHoriz, { className: styles.icon }), onChange: handleSelect, isDropdownIconDisabled: true, shouldCloseAfterClick: false, children: (!isMeetingSelected || !((_f = customer === null || customer === void 0 ? void 0 : customer.p2p) === null || _f === void 0 ? void 0 : _f.inCharge))
                                ? (_jsxs(_Fragment, { children: [((_g = customer === null || customer === void 0 ? void 0 : customer.p2p) === null || _g === void 0 ? void 0 : _g.inCharge)
                                            && (_jsx(DropdownItem, { value: "meeting", icon: _jsx(Event, {}), className: "".concat(styles.item, " ").concat(styles.meeting), children: "Prendre un rendez-vous" })), _jsx(DropdownItem, { icon: _jsx(CheckCircle, { className: styles.icon }), value: "account", className: styles.item, children: "Confirmer le compte Ayomi" }), _jsx(DropdownItem, { icon: _jsx(Loop, { className: styles.icon }), value: "password", className: "".concat(styles.item, " ").concat(styles.warning), children: "R\u00E9initialiser le mot de passe" })] })) : (_jsxs(_Fragment, { children: [_jsx(DropdownItem, { value: "meeting", icon: _jsx(NavigateBefore, {}), className: "".concat(styles.item, " ").concat(styles.meeting, " ").concat(styles.active), children: "Prendre un rendez-vous" }), _jsx(MeetingDropdownList, { inCharge: customer.p2p.inCharge })] })) })] })] }) }));
}
