// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TTLfYbygtT2py4Xcn0f9 {
  grid-area: a;
}

.wlsCbj32jsoHcB0QkQCE {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.UjmfxBy1CEXdUvm9p5Vd {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;
}

.t1I6NgOymNvpxrh7_n4B {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-weight: 500;
  margin: 0;
}

.TTLfYbygtT2py4Xcn0f9 a {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-decoration: unset;
}

.TTLfYbygtT2py4Xcn0f9 svg {
  font-size: 16px;
  margin-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerData/CustomerDataMain/CustomerDataMainProject/CustomerDataMainProject.module.css"],"names":[],"mappings":"AAGA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mDAAwB;EACxB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n.project {\n  grid-area: a;\n}\n\n.row {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n}\n\n.title {\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  margin-bottom: 16px;\n}\n\n.label {\n  color: color-text-subtle;\n  font-weight: 500;\n  margin: 0;\n}\n\n.project a {\n  display: flex;\n  align-items: center;\n  font-weight: 500;\n  text-decoration: unset;\n}\n\n.project svg {\n  font-size: 16px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"project": `TTLfYbygtT2py4Xcn0f9`,
	"row": `wlsCbj32jsoHcB0QkQCE`,
	"title": `UjmfxBy1CEXdUvm9p5Vd`,
	"label": `t1I6NgOymNvpxrh7_n4B`
};
export default ___CSS_LOADER_EXPORT___;
