import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ErrorRounded } from '@mui/icons-material';
import useAPI from 'hooks/useAPI';
import { fromApi } from 'services/network/adaptor/call';
import { getCalls } from 'services/network/api/ringover';
import * as statuses from 'services/store/states';
import styles from './CustomerDataMainCallRecordManager.module.css';
export default function CustomerDataMainCallRecordManager(_a) {
    var phone = _a.phone;
    var _b = useAPI({
        request: function (source) { return getCalls(phone, source); },
        adaptor: fromApi,
    }), status = _b.status, data = _b.data, error = _b.error;
    if (status === statuses.LOADING) {
        return (_jsxs("p", { className: styles.loading, children: [_jsx("span", { className: "spinner black" }), _jsx("span", { className: styles['loading-text'], children: "Chargement des appels en cours..." })] }));
    }
    if (status === statuses.FAIL && !(error === null || error === void 0 ? void 0 : error.includes('404'))) {
        return (_jsxs("p", { className: styles.error, children: [_jsx(ErrorRounded, {}), _jsx("span", { className: styles['error-text'], children: "\u00C9chec de la r\u00E9cup\u00E9ration des appels" })] }));
    }
    if (status === statuses.SUCCESS && data.length > 0) {
        return (_jsxs(_Fragment, { children: [_jsx("p", { className: styles['nb-calls'], children: "".concat(data.length, " appel(s) enregistr\u00E9(s)") }), _jsx("ul", { className: styles.record, children: data.map(function (call) { return (_jsxs("li", { className: styles.call, children: [_jsx("p", { className: styles.date, children: new Date(call.date).toLocaleString() }), _jsxs("p", { children: [_jsx("span", { children: "Appel men\u00E9 par :" }), _jsx("span", { className: styles.name, children: call.fullName })] }), _jsxs("p", { children: [_jsx("span", { children: "Dur\u00E9e :" }), _jsx("span", { className: styles.time, children: "".concat(call.duration, " secondes") })] }), _jsxs("figure", { className: styles.audio, children: [_jsx("figcaption", { className: styles.label, children: "Enregistrement de l'appel :" }), _jsxs("audio", { controls: true, children: [_jsx("source", { src: call.record }), _jsx("track", { kind: "captions", content: "Record track is not available" }), "Votre navigateur ne supporte pas l'audio. Nous vous recommandons d'utiliser la derni\u00E8re version de Chrome."] })] })] }, call.date)); }) })] }));
    }
    return (_jsx("p", { children: " Aucun appel enregistr\u00E9 " }));
}
