// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r_8UPI6ZsUJpiCifbxQk {
  justify-content: space-between;
  align-items: flex-end;
}

.pY7cDKb0s7lr5r4B8u6V {
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 0;
  box-sizing: border-box;
}

.SmCJD7poTwWMsWMqsqcm {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.gK18j6zvsTirzXzAIH8d {
  gap: 16px;
  flex-wrap: wrap;
}

.EDMrNlc2K3FiVmkVQYtu {
  font-family: Robot, sans-serif;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  min-height: 32px;
  height: 32px;
  margin-top: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
}

.OvsWvfICYdBHh4M5XP2B {
  width: 50%;
  min-width: 250px;
  max-width: 400px;
}

.IMOSGu36oEMH2i1SIJAX {
  font-size: 16px !important;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.yCWEOG_C7_d0cS9q4_y2 {
  height: 32px;
  min-height: 32px;
  font-size: 16px;
  box-sizing: border-box;
  align-self: flex-end;
}

@media screen and (max-width: 768px) {
  .NEBeASVUV39qsTRDMjeT {
    min-width: unset;
    max-width: unset;
    width: 150px;
    box-sizing: border-box;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Search/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAGA;EACE,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,mDAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;EACxB;AACF","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n.container {\n  justify-content: space-between;\n  align-items: flex-end;\n}\n\n.searchbar {\n  border-bottom: 1px solid #e9e9e9;\n  padding: 16px 0;\n  box-sizing: border-box;\n}\n\n.label {\n  display: flex;\n  flex-direction: column;\n  font-family: Roboto, sans-serif;\n  font-weight: 500;\n  font-size: 14px;\n}\n\n.row {\n  gap: 16px;\n  flex-wrap: wrap;\n}\n\n.input {\n  font-family: Robot, sans-serif;\n  border: 1px solid #e4e4e4;\n  border-radius: 4px;\n  min-height: 32px;\n  height: 32px;\n  margin-top: 4px;\n  padding: 4px 8px;\n  box-sizing: border-box;\n}\n\n.search {\n  width: 50%;\n  min-width: 250px;\n  max-width: 400px;\n}\n\n.icon {\n  font-size: 16px !important;\n  color: color-text-subtle;\n}\n\n.submit {\n  height: 32px;\n  min-height: 32px;\n  font-size: 16px;\n  box-sizing: border-box;\n  align-self: flex-end;\n}\n\n@media screen and (max-width: 768px) {\n  .dropdown {\n    min-width: unset;\n    max-width: unset;\n    width: 150px;\n    box-sizing: border-box;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"container": `r_8UPI6ZsUJpiCifbxQk`,
	"searchbar": `pY7cDKb0s7lr5r4B8u6V`,
	"label": `SmCJD7poTwWMsWMqsqcm`,
	"row": `gK18j6zvsTirzXzAIH8d`,
	"input": `EDMrNlc2K3FiVmkVQYtu`,
	"search": `OvsWvfICYdBHh4M5XP2B`,
	"icon": `IMOSGu36oEMH2i1SIJAX`,
	"submit": `yCWEOG_C7_d0cS9q4_y2`,
	"dropdown": `NEBeASVUV39qsTRDMjeT`
};
export default ___CSS_LOADER_EXPORT___;
