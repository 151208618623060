var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as types from './types';
var initState = {
    status: 'IDLE',
    roles: [],
};
export default function rolesReducer(state, _a) {
    if (state === void 0) { state = initState; }
    var _b = _a === void 0 ? {} : _a, type = _b.type, payload = _b.payload;
    switch (type) {
        case types.FETCH_ROLE_REQUEST:
            return __assign(__assign({}, state), { status: 'LOADING' });
        case types.FETCH_ROLE_SUCCEEDED:
            return __assign(__assign(__assign({}, state), { status: 'SUCCEEDED' }), payload);
        case types.FETCH_ROLE_FAILED:
            return __assign(__assign({}, state), { status: 'FAILED' });
        default:
            return state;
    }
}
