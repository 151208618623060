// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iFUCq5357wYYUg6q2kA0 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}

.MtQf_K_n5Y3olb55K6cb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.Qda3xt9k91_coQ6_YOnt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.JyrAImbc_pIrtEISB59j {
  margin-right: 4px;
}

.FacTs62bh8VyMXnTpAcF {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  font-weight: 500;
  margin: 8px 0 0;
}

.o4q4SkxMyxEd5aWdriex {
  margin: 0;
  font-weight: 500;
}

.o1OElDv6hQsOHNDmUPKq {
  min-height: 32px;
}

.o1OElDv6hQsOHNDmUPKq.PxdyoSssfpZZD2nEW_Yc svg,
.o1OElDv6hQsOHNDmUPKq.PxdyoSssfpZZD2nEW_Yc span {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success"]};
}

.m030KW1HaOQNcrd3eXDP {
  margin: 4px 0 0;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.cXSfsKGsULFYBIFv4V4V {
  margin-top: 1rem;
}

.An349WgYr2cRwJrTUFA8 {
  margin-top: 4px;
  height: 32px;
  min-height: 32px;
  font-size: 1rem;
  margin-left: auto;
}

.xyPNToYIEu6hGhTt1ffX {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerData/CustomerDataMain/CustomerDataMainComment/CustomerDataMainComment.module.css"],"names":[],"mappings":"AAGA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mDAAmB;EACnB,8DAAiC;EACjC,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,mDAAoB;AACtB;;AAEA;EACE,eAAe;EACf,mDAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple, color-success, color-purple-bg, color-text-subtle from colors;\n\n.title {\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  margin-bottom: 1rem;\n}\n\n.comment {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 1rem;\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.icon {\n  margin-right: 4px;\n}\n\n.logo {\n  width: 32px;\n  height: 32px;\n  border-radius: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: color-purple;\n  background-color: color-purple-bg;\n  font-weight: 500;\n  margin: 8px 0 0;\n}\n\n.username {\n  margin: 0;\n  font-weight: 500;\n}\n\n.custom {\n  min-height: 32px;\n}\n\n.custom.success svg,\n.custom.success span {\n  color: color-success;\n}\n\n.text {\n  margin: 4px 0 0;\n  color: color-text-subtle;\n}\n\n.input {\n  margin-top: 1rem;\n}\n\n.submit {\n  margin-top: 4px;\n  height: 32px;\n  min-height: 32px;\n  font-size: 1rem;\n  margin-left: auto;\n}\n\n.main {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-success": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"title": `iFUCq5357wYYUg6q2kA0`,
	"comment": `MtQf_K_n5Y3olb55K6cb`,
	"header": `Qda3xt9k91_coQ6_YOnt`,
	"icon": `JyrAImbc_pIrtEISB59j`,
	"logo": `FacTs62bh8VyMXnTpAcF`,
	"username": `o4q4SkxMyxEd5aWdriex`,
	"custom": `o1OElDv6hQsOHNDmUPKq`,
	"success": `PxdyoSssfpZZD2nEW_Yc`,
	"text": `m030KW1HaOQNcrd3eXDP`,
	"input": `cXSfsKGsULFYBIFv4V4V`,
	"submit": `An349WgYr2cRwJrTUFA8`,
	"main": `xyPNToYIEu6hGhTt1ffX`
};
export default ___CSS_LOADER_EXPORT___;
