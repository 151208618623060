export var toLead = function (data) { return ({
    id: data.id,
    date: data.date,
    name: data.name,
    email: data.email,
    phone: data.phone,
    type: data.lead_type,
    meeting: data.rdv_type,
    comment: data.comment || '',
    dailyCall: data.number_daily_call,
    totalCall: data.number_total_call,
}); };
export var toBackLead = function (data, status, reason) { return ({
    id: data.id,
    date: data.date,
    email: data.email,
    phone: data.phone,
    comment: data.comment,
    name: data.name,
    status: status,
    reason: reason,
    lead_type: data.type,
    rdv_type: data.meeting,
    number_daily_call: data.dailyCall,
    number_total_call: data.totalCall,
}); };
