var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { UploadFile } from '@mui/icons-material';
import LoadingSpinner from 'components/library/LoadingSpinner';
import ErrorAnimation from 'components/library/ErrorAnimation';
import SuccessAnimation from 'components/library/SuccessAnimation/SuccessAnimation';
import Button from 'components/library/Button';
import Dropdown from 'components/library/Dropdown';
import DropdownItem from 'components/library/DropdownItem';
import * as statuses from 'services/store/states';
import { uploadFacebookLeads } from 'services/network/api/leads';
import useOutsideClickAlert from 'hooks/useOutsideClick';
import getErrorMessage from 'utils/error';
import styles from './LeadsToolbarUpload.module.css';
import { logError } from 'utils/logs';
export default function LeadsToolbarUpload() {
    var _this = this;
    var _a = useState(statuses.IDLE), status = _a[0], setStatus = _a[1];
    var _b = useState(false), isOpenType = _b[0], setOpenType = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var _d = useState('sdr'), type = _d[0], setType = _d[1];
    var _e = useState(''), message = _e[0], setMessage = _e[1];
    var input = useRef(null);
    var modal = useRef(null);
    useOutsideClickAlert(modal, function () { return setOpenType(false); });
    var reset = function () { return setStatus(statuses.IDLE); };
    var handleFile = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var files, formData, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStatus(statuses.LOADING);
                    setOpenType(false);
                    files = e.target.files;
                    if (!files) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    formData = new FormData();
                    formData.append('file', files[0]);
                    formData.append('name', 'facebook-leads');
                    formData.append('csv_type', type);
                    return [4 /*yield*/, uploadFacebookLeads(formData)];
                case 2:
                    data = (_a.sent()).data;
                    setMessage(JSON.stringify(data));
                    setStatus(statuses.SUCCESS);
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    setError(getErrorMessage(err_1));
                    setStatus(statuses.FAIL);
                    return [3 /*break*/, 5];
                case 4:
                    setOpenType(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var openType = function () { return setOpenType(function (curr) { return !curr; }); };
    var onTypeChange = function (newType) { return setType(newType); };
    var openFile = function () {
        if (input.current)
            input.current.click();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { onClick: openType, className: styles.small, variant: "outline", children: [_jsx(UploadFile, { className: styles.icon }), "Importer csv"] }), _jsx("input", { type: "file", ref: input, onChange: handleFile, className: styles.hide }), (isOpenType)
                && (_jsx("div", { className: "fadeIn ".concat(styles.background), children: _jsxs("div", { className: styles.modal, ref: modal, children: [_jsx("p", { className: styles.status, children: "Importation CSV" }), _jsx("p", { children: "Quel type de fichier s'agit-il ?" }), _jsxs(Dropdown, { value: type, onChange: onTypeChange, className: styles.dropdown, children: [_jsx(DropdownItem, { value: "sdr", children: "Facebook" }), _jsx(DropdownItem, { value: "p2p", children: "P2P" }), _jsx(DropdownItem, { value: "reputation", children: "Trustpilot" })] }), _jsxs(Button, { onClick: openFile, className: "".concat(styles.small, " ").concat(styles.import), children: [_jsx(UploadFile, { className: styles.icon }), "Importer csv"] })] }) })), (status === statuses.LOADING)
                && (_jsx("div", { className: "fadeIn ".concat(styles.background), children: _jsxs("div", { className: styles.modal, children: [_jsx("p", { className: styles.loading, children: "Chargement des leads en cours..." }), _jsx(LoadingSpinner, {})] }) })), (status === statuses.FAIL)
                && (_jsx("div", { className: "fadeIn ".concat(styles.background), children: _jsxs("div", { className: styles.modal, children: [_jsx(ErrorAnimation, {}), _jsx("p", { className: styles.status, children: "Le fichier csv n'a pas \u00E9t\u00E9 import\u00E9" }), _jsx("p", { className: styles.description, children: error }), _jsx(Button, { onClick: reset, className: styles.discrete, children: "Fermer" })] }) })), (status === statuses.SUCCESS)
                && (_jsx("div", { className: "fadeIn ".concat(styles.background), children: _jsxs("div", { className: styles.modal, children: [_jsx(SuccessAnimation, {}), _jsx("p", { className: styles.status, children: "Le fichier a \u00E9t\u00E9 import\u00E9 avec succ\u00E8s" }), _jsx("p", { className: styles['success-message'], children: message }), _jsx(Button, { onClick: reset, className: styles.discrete, children: "Fermer" })] }) }))] }));
}
