import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FileCopy, Error } from '@mui/icons-material';
import { copyTextToClipboard } from 'utils/copyToClipboard';
import * as statuses from 'services/store/states';
import AccountLoading from '../library/LoadingSpinner';
import Button from '../library/Button/Button';
import ErrorAnimation from '../library/ErrorAnimation';
import styles from './LoginPopUp.module.css';
export default function LoginPopUp(_a) {
    var status = _a.status, close = _a.close, error = _a.error, loading = _a.loading, success = _a.success, value = _a.value;
    var _b = useState(false), isCopy = _b[0], setIsCopy = _b[1];
    var _c = useState(true), isCopySupported = _c[0], setIsCopySupported = _c[1];
    var handleUrlCopy = function () {
        var onSuccess = function () {
            setIsCopy(true);
            window.setTimeout(function () { return setIsCopy(false); }, 1000);
        };
        var onError = function () {
            setIsCopy(false);
            setIsCopySupported(false);
        };
        if (value) {
            copyTextToClipboard(value, onSuccess, onError);
        }
        else {
            onError();
        }
    };
    return (_jsx("div", { className: styles.background, children: _jsxs("section", { className: styles.modal, children: [(status === statuses.LOADING)
                    && (_jsxs(_Fragment, { children: [_jsx("h3", { className: styles.title, children: loading }), _jsx(AccountLoading, {}), _jsx(Button, { variant: "outline", onClick: close, className: styles.button, children: "Cancel" })] })), (status === statuses.FAIL)
                    && (_jsxs(_Fragment, { children: [_jsx("h3", { className: styles.title, children: "Request failed" }), _jsx("p", { className: styles.description, children: error }), _jsx(ErrorAnimation, {}), _jsx(Button, { onClick: close, className: styles.button, children: "OK" })] })), (status === statuses.SUCCESS)
                    && (_jsxs(_Fragment, { children: [_jsx("h3", { className: styles.title, children: "Request succeeded" }), _jsx("p", { className: styles.description, children: success }), (isCopySupported)
                                ? (_jsx(Button, { type: "custom", variant: "ghost", className: styles.custom, disabled: isCopy, onClick: handleUrlCopy, children: _jsxs(_Fragment, { children: [(!isCopy) && _jsx(FileCopy, { className: styles.icon }), (!isCopy) ? 'Copy' : 'Copied !'] }) }))
                                : (_jsxs("p", { className: styles.error, children: [_jsx("span", { className: styles.lineHeight, children: _jsx(Error, { className: styles.icon }) }), _jsx("span", { children: "Direct copy to clipboard is not supported yet by your browser. Please copy directly the following link by hand." })] })), _jsx("p", { className: styles.token, children: value }), _jsx(Button, { className: styles.button, onClick: close, children: "Close" })] }))] }) }));
}
