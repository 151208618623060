import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'components/library/Button';
import layout from 'components/library/style/layout.module.css';
import styles from './LeadsTunnelCustomerError.module.css';
export default function LeadsTunnelCustomerError(_a) {
    var error = _a.error, onReload = _a.onReload;
    var onClick = function () { return onReload({}); };
    if (error && error.includes('404')) {
        return (_jsxs("section", { className: "".concat(layout.container, " ").concat(styles.section), children: [_jsx("p", { className: styles.title, children: "Aucun lead facebook n'est actuellement disponible" }), _jsx(Button, { onClick: onClick, children: "Actualiser le tunnel" })] }));
    }
    return (_jsx("section", { className: layout.container, children: _jsx("p", { children: error }) }));
}
