import apm from 'rum';
export var addLog = function () {
    fetch('/log/');
};
export var logError = function (err, info) {
    if (info === void 0) { info = { componentStack: '' }; }
    apm.captureError(err);
    if (info.componentStack)
        apm.captureError(info.componentStack);
};
