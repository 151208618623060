export var toCamel = function (s) { return s.replace(/([-_][a-z])/ig, function ($1) { return $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''); }); };
export var toSnake = function (s) {
    if (s === s.toUpperCase())
        return s;
    return s.replace(/[A-Z]/g, function (m) { return "_".concat(m.toLowerCase()); });
};
export var toHuman = function (s) {
    if (!s)
        return '';
    if (s === s.toUpperCase())
        return s;
    return s.replace(/[A-Z]/g, function (m) { return " ".concat(m.toLowerCase()); }).replaceAll('_', ' ');
};
var isArray = function (a) { return Array.isArray(a); };
var isObject = function (o) { return o === Object(o) && !isArray(o) && typeof o !== 'function'; };
export var keysToCamel = function (o) {
    if (isObject(o)) {
        var n_1 = o;
        Object.keys(o)
            .forEach(function (k) {
            n_1[toCamel(k)] = keysToCamel(o[k]);
        });
        return n_1;
    }
    if (isArray(o)) {
        return o.map(function (i) { return keysToCamel(i); });
    }
    return o;
};
export var keysToSnake = function (o) {
    if (isObject(o)) {
        var n_2 = o;
        Object.keys(o)
            .forEach(function (k) {
            n_2[toSnake(k)] = keysToSnake(o[k]);
        });
        return n_2;
    }
    if (isArray(o)) {
        return o.map(function (i) { return keysToSnake(i); });
    }
    return o;
};
export var capitalize = function (str) { return "".concat(str[0].toUpperCase()).concat(str.slice(1)); };
