import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { getCustomersByQuery } from 'services/network/api/customers';
import * as statuses from 'services/store/states';
import { fromBackCustomers } from 'services/network/adaptor/customers';
import useAPI from 'hooks/useAPI';
import Loading from 'components/library/Loading';
import LoadingSpinner from 'components/library/LoadingSpinner';
import layout from 'components/library/style/layout.module.css';
import getErrorMessage from 'utils/error';
export default function SearchManager(_a) {
    var query = _a.query, type = _a.type, children = _a.children;
    var _b = useAPI({
        request: function (source) { return getCustomersByQuery(source, { query: query, type: type }); },
        onError: function (err) {
            var _a, _b;
            return getErrorMessage(((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || err);
        },
        adaptor: fromBackCustomers,
    }), status = _b.status, data = _b.data, error = _b.error, fetchData = _b.fetchData;
    useEffect(function () {
        if (status !== statuses.IDLE && status !== statuses.LOADING) {
            fetchData({});
        }
    }, [query, type]);
    return (_jsx(Loading, { status: status, fallback: _jsx(LoadingSpinner, {}), errorFallback: (_jsx("div", { className: "".concat(layout.container), children: (error === null || error === void 0 ? void 0 : error.includes('Too much users'))
                ? (_jsx("p", { children: "Merci de pr\u00E9ciser votre recherche : ".concat(error) }))
                : (_jsx("p", { children: error })) })), children: children(data) }));
}
