// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vq6MQj1IbOldjXsltZ0c {
  display: grid;
  grid-template-columns: 1.25fr 1.5fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  gap: 16px;
  align-items: center;
}

.oyXRuLYpcbWNK9EfBuTW:nth-child(even) {
  background-color: #f5f7fb;
}

@media screen and (max-width: 768px) {
  .vq6MQj1IbOldjXsltZ0c {
    grid-template-columns: 1.25fr 1fr 1fr 0.5fr;
    gap: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Search/SearchResults/SearchResults.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6DAA6D;EAC7D,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,2CAA2C;IAC3C,SAAS;EACX;AACF","sourcesContent":[".row {\n  display: grid;\n  grid-template-columns: 1.25fr 1.5fr 1fr 1fr 1fr 1fr 1fr 0.5fr;\n  gap: 16px;\n  align-items: center;\n}\n\n.background:nth-child(even) {\n  background-color: #f5f7fb;\n}\n\n@media screen and (max-width: 768px) {\n  .row {\n    grid-template-columns: 1.25fr 1fr 1fr 0.5fr;\n    gap: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `vq6MQj1IbOldjXsltZ0c`,
	"background": `oyXRuLYpcbWNK9EfBuTW`
};
export default ___CSS_LOADER_EXPORT___;
