var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckCircle, HelpCenter, Laptop, Loop, Person, PointOfSale, } from '@mui/icons-material';
import { Modal } from '@mui/material';
import layout from 'components/library/style/layout.module.css';
import Dropdown from 'components/library/Dropdown';
import Button from 'components/library/Button';
import LoginPopUp from 'components/LoginPopUp';
import Input from 'components/library/Input';
import DropdownItem from 'components/library/DropdownItem';
import VisioScheduler from 'components/VisioScheduler';
import { CUSTOMER_CARE_CALENDAR, SALES_CALENDAR } from 'constants/calendar';
import { getCalendarUrl } from 'services/controller/customers';
import { getUserToken } from 'services/network/api/auth';
import { callBackUser, callBackUserRi } from 'services/network/api/customers';
import { isUserAuthorized } from 'services/store/features/roles/actions';
import { addToast } from 'services/store/features/toasts/actions';
import * as statuses from 'services/store/states';
import getErrorMessage from 'utils/error';
import { handleAccountConfirmation, handleResetPassword } from 'utils/auth';
import styles from '../CustomerDataHeader.module.css';
import ActionsInvestDenial from './ActionsInvestDenial';
import { logError } from 'utils/logs';
export default function CustomerDataHeaderActions(_a) {
    var _this = this;
    var _b;
    var roles = _a.roles, customer = _a.customer, iss = _a.iss;
    var _c = useState(statuses.IDLE), meetingStatus = _c[0], setMeetingStatus = _c[1];
    var _d = useState(statuses.IDLE), loginStatus = _d[0], setLoginStatus = _d[1];
    var _e = useState(undefined), token = _e[0], setToken = _e[1];
    var _f = useState(false), isOpen = _f[0], setIsOpen = _f[1];
    var _g = useState('other'), source = _g[0], setSource = _g[1];
    var _h = useState(false), isVisioOpen = _h[0], setIsVisioOpen = _h[1];
    var dispatch = useDispatch();
    var resetPassword = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleResetPassword(customer.email, dispatch)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var confirmAccount = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleAccountConfirmation(customer.email, dispatch)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var loginAsUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoginStatus(statuses.LOADING);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getUserToken(customer.email)];
                case 2:
                    data = (_a.sent()).data;
                    if (data.status === 'OK') {
                        setLoginStatus(statuses.SUCCESS);
                        setToken(data.data.refresh_token);
                    }
                    else {
                        setLoginStatus(statuses.FAIL);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    setLoginStatus(statuses.FAIL);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleMeeting = function (value) {
        if (value === 'sales') {
            window.open(getCalendarUrl(SALES_CALENDAR, customer, iss));
        }
        else if (value === 'customer_care') {
            window.open(getCalendarUrl(CUSTOMER_CARE_CALENDAR, customer, iss));
        }
        else if (value === 'waiting_visio') {
            setIsVisioOpen(true);
        }
    };
    var onSourceChange = function (e) {
        setSource(e.target.value);
    };
    var onCallBack = function (type) { return __awaiter(_this, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsOpen(false);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    setMeetingStatus(statuses.LOADING);
                    if (!(type === 'sales')) return [3 /*break*/, 3];
                    return [4 /*yield*/, callBackUser({ email: customer.email })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, callBackUserRi({
                        email: customer.email,
                        id_project: customer.idAyomi,
                        template: 'invest',
                        source: source,
                    })];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    setMeetingStatus(statuses.SUCCESS);
                    dispatch(addToast({
                        type: 'success',
                        title: "Le lead remontera en priorit\u00E9 sur le soft ".concat(type),
                        description: '',
                    }));
                    return [3 /*break*/, 7];
                case 6:
                    err_2 = _a.sent();
                    logError(err_2);
                    setMeetingStatus(statuses.FAIL);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de prendre rendez-vous',
                        description: getErrorMessage(err_2),
                    }));
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onCallBackSales = function () { return onCallBack('sales'); };
    var onCallBackRi = function () { return setIsOpen(true); };
    return (_jsxs(_Fragment, { children: [(isOpen)
                && (_jsx(Modal, { open: isOpen, className: styles['modal-container'], children: _jsxs("div", { className: styles.modal, children: [_jsx("h2", { children: "\u00C0 rappeler imm\u00E9diatement RI" }), _jsx(Input, { label: "Source", name: "source", className: styles.select, children: _jsxs("select", { id: "source", value: source, onChange: onSourceChange, className: styles['select-input'], children: [_jsx("option", { value: "p2p", children: "Passe P2P" }), _jsx("option", { value: "email", children: "Email" }), _jsx("option", { value: "whatsapp", children: "What's app" }), _jsx("option", { value: "phone", children: "T\u00E9l\u00E9phone" }), _jsx("option", { value: "zendesk", children: "Zendesk" }), _jsx("option", { value: "other", children: "Autre" })] }) }), _jsx(Button, { onClick: function () { return onCallBack('ri'); }, children: "Envoyer" })] }) })), (isVisioOpen)
                && (_jsx(VisioScheduler, { email: customer.email, projectId: customer.idAyomi, onClose: function () { return setIsVisioOpen(false); } })), _jsxs("div", { className: "".concat(layout.row, " ").concat(styles.actions), children: [(loginStatus !== statuses.IDLE)
                        && (_jsx(LoginPopUp, { status: loginStatus, error: "Oups ! Impossible de se connecter.", loading: "Connexion en cours...", success: "Connexion en tant que client r\u00E9ussi !", value: "https://".concat(process.env.REACT_APP_DEBUG ? 'dev-' : '', "auth.ayomi.fr/?refresh_token=").concat(token), close: function () { return setLoginStatus(statuses.IDLE); } })), (customer.type.includes('prospect') || ((_b = customer.p2p) === null || _b === void 0 ? void 0 : _b.status) === 'sales')
                        && (_jsx(Button, { className: "".concat(styles.button, " ").concat(styles.action), onClick: onCallBackSales, disabled: meetingStatus !== statuses.IDLE, children: (meetingStatus === statuses.LOADING)
                                ? _jsx("div", { className: "spinner" })
                                : 'À rappeler immédiatement Sales' })), customer.type.includes('invest')
                        && (_jsx(Button, { className: "".concat(styles.button, " ").concat(styles.action), onClick: onCallBackRi, disabled: meetingStatus !== statuses.IDLE, children: (meetingStatus === statuses.LOADING)
                                ? _jsx("div", { className: "spinner" })
                                : 'À rappeler immédiatement RI' })), customer.type.includes('invest')
                        && (_jsx(ActionsInvestDenial, {})), _jsxs(Dropdown, { onChange: handleMeeting, value: "Prendre rendez-vous", className: "".concat(styles.button, " ").concat(styles.action), children: [_jsx(DropdownItem, { value: "sales", icon: _jsx(PointOfSale, {}), className: styles.item, children: "Rendez-vous Sales" }), _jsx(DropdownItem, { value: "customer_care", icon: _jsx(HelpCenter, {}), className: styles.item, children: "Rendez-vous Customer Care" }), customer.type.includes('p2p')
                                && (_jsx(DropdownItem, { value: "waiting_visio", icon: _jsx(Laptop, {}), className: styles.item, children: "RDV Visioconf\u00E9rence" }))] }), (customer.iss)
                        && (_jsxs(_Fragment, { children: [isUserAuthorized(roles, ['AUTH_AS_USER'])
                                    && (_jsxs(Button, { variant: "outline", onClick: loginAsUser, className: styles.action, children: [_jsx(Person, { className: styles.icon }), _jsx("span", { children: "Login as ".concat(customer.username) })] })), _jsxs(Button, { variant: "outline", onClick: resetPassword, className: styles.action, children: [_jsx(Loop, { className: styles.icon }), _jsx("span", { children: "R\u00E9initialiser mot de passe" })] }), _jsxs(Button, { variant: "outline", onClick: confirmAccount, className: styles.action, children: [_jsx(CheckCircle, { className: styles.icon }), _jsx("span", { children: "Confirmer le compte" })] })] }))] })] }));
}
