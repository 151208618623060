// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fHblgjdQD2ioFKFXG94G > div {
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
    border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/library/Input/Input.module.css"],"names":[],"mappings":"AAGA;IACI,mDAAkB;IAClB,0DAAyB;AAC7B","sourcesContent":["@value colors: \"../style/colors.css\";\n@value color-error from colors;\n\n.ERROR > div {\n    color: color-error;\n    border-color: color-error;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../style/colors.css"`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"ERROR": `fHblgjdQD2ioFKFXG94G`
};
export default ___CSS_LOADER_EXPORT___;
