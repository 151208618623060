import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OpenInNew } from '@mui/icons-material';
import { getCalendarUrl } from 'services/controller/customers';
import styles from './CustomerDataMainProjectsLinked.module.css';
export default function CustomerDataMainProjectsLinked(_a) {
    var projects = _a.projects, customer = _a.customer, iss = _a.iss;
    return (_jsxs("div", { children: [_jsx("p", { className: styles.title, children: "Projets associ\u00E9s" }), (projects && projects.length > 0)
                ? (projects.map(function (project) {
                    var _a;
                    return (_jsxs("div", { className: styles.project, children: [_jsx("div", { className: styles.name, children: project.name[0] }), _jsxs("div", { children: [_jsxs("div", { className: styles.info, children: [_jsx("p", { className: styles.title, children: "".concat(project.name, " - ").concat((project === null || project === void 0 ? void 0 : project.p2p) || 'N/A') }), _jsxs("p", { className: "".concat(styles.status, " ").concat(styles[project.status.toLowerCase()]), children: [_jsx("span", {}), "".concat(project.status, " ").concat((project.status.toLowerCase() === 'closing') ? (" : ".concat(((_a = project === null || project === void 0 ? void 0 : project.importantDate) === null || _a === void 0 ? void 0 : _a.realEndDate) || 'N/A')) : '')] })] }), _jsxs("div", { className: "".concat(styles.info, " ").concat(styles.description), children: [_jsx("p", { children: "Project ID : ".concat(project.id) }), _jsxs("p", { children: ["RI associ\u00E9 :", _jsxs("a", { href: getCalendarUrl(project.inCharge.ri.calendar, customer, iss), target: "_blank", rel: "noreferrer", children: [project.inCharge.ri.username, _jsx(OpenInNew, { className: styles.icon })] })] })] })] })] }));
                }))
                : (_jsx("p", { children: " Aucun projet associ\u00E9 " }))] }));
}
