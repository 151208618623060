var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { confirmAccount, resetPassword } from 'services/network/api/auth';
import { addToast } from 'services/store/features/toasts/actions';
import { updateCustomerById } from 'services/network/api/customers';
import getErrorMessage from './error';
import { logError } from './logs';
export var handleResetPassword = function (email, dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var data, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, resetPassword(email)];
            case 1:
                data = (_a.sent()).data;
                if (data.status === 'OK') {
                    dispatch(addToast({
                        type: 'success',
                        title: 'Un email a été envoyé avec un nouveau mot de passe',
                        description: "Email envoy\u00E9 \u00E0 ".concat(email),
                    }));
                }
                else {
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de réinitialiser le mot de passe du compte',
                        description: data.msg,
                    }));
                }
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                logError(err_1);
                dispatch(addToast({
                    type: 'error',
                    title: 'Impossible de réinitialiser le mot de passe du compte',
                    description: getErrorMessage(err_1),
                }));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var handleAccountConfirmation = function (email, dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var data, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, confirmAccount(email)];
            case 1:
                data = (_a.sent()).data;
                if (data.status === 'OK') {
                    dispatch(addToast({
                        type: 'success',
                        title: 'Le compte a été confirmé',
                        description: "Le compte ".concat(email, " a \u00E9t\u00E9 confirm\u00E9 avec succ\u00E8s."),
                    }));
                }
                else {
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de confirmer le compte',
                        description: data.msg,
                    }));
                }
                return [3 /*break*/, 3];
            case 2:
                err_2 = _a.sent();
                logError(err_2);
                dispatch(addToast({
                    type: 'error',
                    title: 'Impossible de confirmer le compte',
                    description: getErrorMessage(err_2),
                }));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var handleCustomerBookmarked = function (email, isBookmarked, idAyomi, dispatch, callback) { return __awaiter(void 0, void 0, void 0, function () {
    var newCustomer, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                newCustomer = {
                    email: email,
                    id_ayomi: idAyomi,
                    favorite: isBookmarked,
                };
                return [4 /*yield*/, updateCustomerById(newCustomer)];
            case 1:
                _a.sent();
                if (callback)
                    callback(isBookmarked);
                return [3 /*break*/, 3];
            case 2:
                err_3 = _a.sent();
                logError(err_3);
                dispatch(addToast({
                    type: 'error',
                    title: 'Impossible d\'ajouter ce client en favoris !',
                    description: getErrorMessage(err_3),
                }));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
