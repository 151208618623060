var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowBack, Bookmark, BookmarkBorder, Notifications, Mail, Phone, Tag, } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import Button from 'components/library/Button';
import layout from 'components/library/style/layout.module.css';
import { handleCustomerBookmarked } from 'utils/auth';
import { toHuman } from 'utils/converter';
import CustomerDataHeaderActions from './CustomerDataHeaderActions';
import styles from './CustomerDataHeader.module.css';
export default function CustomerDataHeader(_a) {
    var _this = this;
    var _b, _c;
    var customer = _a.customer;
    var _d = useState(customer.isBookmarked), isBookmarked = _d[0], setIsBookmarked = _d[1];
    var dispatch = useDispatch();
    var markAsBookmarked = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleCustomerBookmarked(customer.email, !isBookmarked, customer.idAyomi, dispatch, setIsBookmarked)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: styles.content, children: _jsxs("header", { className: "".concat(layout.container, " ").concat(styles.header), children: [_jsxs(NavLink, { to: "/search", className: styles.back, children: [_jsx(ArrowBack, { className: styles.icon }), _jsx("span", { children: "Retour aux r\u00E9sultats" })] }), _jsxs("div", { className: "".concat(layout.row, " ").concat(styles.space), children: [_jsxs("div", { className: styles.user, children: [_jsxs("div", { className: "".concat(layout.row, " ").concat(styles.container), children: [_jsxs("p", { className: styles.name, children: [customer.username, _jsx("span", { className: styles.id, children: "#".concat(customer.iss) })] }), _jsx(Button, { onClick: markAsBookmarked, className: styles.bookmark, children: isBookmarked
                                                ? _jsx(Bookmark, { className: styles.icon })
                                                : _jsx(BookmarkBorder, { className: styles.icon }) })] }), _jsxs("div", { className: styles.grid, children: [_jsxs("p", { className: styles.label, children: [_jsx(Mail, { className: styles.icon }), "Email"] }), _jsx("p", { className: styles.value, children: customer.email }), _jsxs("p", { className: styles.label, children: [_jsx(Phone, { className: styles.icon }), "T\u00E9l\u00E9phone"] }), _jsx("p", { className: styles.value, children: customer.phone }), _jsxs("p", { className: styles.label, children: [_jsx(Bookmark, { className: styles.icon }), "Tags"] }), _jsx("p", { className: styles.value, children: ((_b = customer.tags) === null || _b === void 0 ? void 0 : _b.map(function (tag) { return (_jsx("span", { className: "".concat(styles.tag, " ").concat(styles[tag]), children: toHuman(tag) }, tag)); })) || 'N/A' }), ((_c = customer.tags) === null || _c === void 0 ? void 0 : _c.includes('invest'))
                                            && (_jsxs(_Fragment, { children: [_jsxs("p", { className: styles.label, children: [_jsx(Tag, { className: styles.icon }), "Segment"] }), _jsx("p", { className: styles.value, children: customer.segment || 'N/A' }), _jsxs("p", { className: styles.label, children: [_jsx(Notifications, { className: styles.icon }), "Lead Status"] }), _jsx("p", { className: styles.value, children: customer.leadStatus || 'N/A' })] }))] })] }), _jsx(CustomerDataHeaderActions, { customer: customer })] })] }) }));
}
