// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g5B85iQVpDS9vDufy9GD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

.rkQHscGesExAoBVYQMqg {
  font-weight: 500;
  font-size: 24px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}
`, "",{"version":3,"sources":["webpack://./src/views/Leads/LeadsTunnel/LeadsTunnelCustomer/LeadsTunnelCustomerError/LeadsTunnelCustomerError.module.css"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mDAAwB;AAC1B","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n.section {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 3rem;\n}\n\n.title {\n  font-weight: 500;\n  font-size: 24px;\n  color: color-text-subtle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"section": `g5B85iQVpDS9vDufy9GD`,
	"title": `rkQHscGesExAoBVYQMqg`
};
export default ___CSS_LOADER_EXPORT___;
