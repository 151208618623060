import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import Loading from 'components/library/Loading';
import LoadingSpinner from 'components/library/LoadingSpinner';
import useAPI from 'hooks/useAPI';
import { createLog, getNextLead } from 'services/network/api/leads';
import { toLead } from 'services/network/adaptor/leads';
import styles from './LeadsTunnel.module.css';
import LeadsTunnelCustomer from './LeadsTunnelCustomer';
import LeadsTunnelCustomerError from './LeadsTunnelCustomer/LeadsTunnelCustomerError';
export default function LeadsTunnel(_a) {
    var lead = _a.lead;
    var _b = useAPI({
        request: function (source) { return getNextLead(source); },
        adaptor: toLead,
    }), status = _b.status, data = _b.data, error = _b.error, fetchData = _b.fetchData, setData = _b.setData;
    useEffect(function () {
        if ((data === null || data === void 0 ? void 0 : data.id) !== undefined) {
            createLog(data.id, 'getLead');
        }
    }, [data === null || data === void 0 ? void 0 : data.id]);
    useEffect(function () {
        if (lead) {
            setData(lead);
        }
    }, [lead]);
    return (_jsx("section", { className: styles.tunnel, children: _jsx(Loading, { status: status, fallback: _jsx(LoadingSpinner, {}), errorFallback: _jsx(LeadsTunnelCustomerError, { error: error, onReload: fetchData }), children: _jsx(LeadsTunnelCustomer, { lead: data, getNewLead: fetchData, setLead: setData }) }) }));
}
