// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2.Umhg2tGwg0vbX80MWQ0B {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.c3zHZ5HwQbVEkdvTegcE {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.c3zHZ5HwQbVEkdvTegcE label {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 1rem;
}

.pAIbdTUgokUms2zaBkL2 {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
}

.c3zHZ5HwQbVEkdvTegcE textarea {
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: 0.3s;
}

.c3zHZ5HwQbVEkdvTegcE textarea:hover,
.c3zHZ5HwQbVEkdvTegcE textarea:focus {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  outline: none;
}

.c3zHZ5HwQbVEkdvTegcE select {
  border: none;
  border-right: 0.5rem solid white;
  outline: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s;
}

.c3zHZ5HwQbVEkdvTegcE select:hover,
.c3zHZ5HwQbVEkdvTegcE select:focus {
  outline-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

._ifU2Deh43uiwnnx1lUc {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.gZP8b6WrWWwANUPAisyy {
  max-height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerData/CustomerDataHeader/CustomerDataHeaderActions/ActionsInvestDenial/ActionsInvestDenial.module.css"],"names":[],"mappings":"AAGA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,SAAS;AACX;;AAEA;EACE,8DAA4B;EAC5B,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,0DAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,+DAA6B;EAC7B,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;;EAEE,2DAA2B;AAC7B;;AAEA;EACE,8DAAmC;EACnC,mDAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple, color-gray, color-error-light, color-error from colors;\n\nh2.title {\n  font-size: 1.25rem;\n  font-weight: 500;\n  font-family: Roboto, sans-serif;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.form label {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n  font-size: 1rem;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  gap: 1rem;\n}\n\n.form textarea {\n  border: 1px solid color-gray;\n  border-radius: 0.25rem;\n  padding: 0.5rem;\n  transition: 0.3s;\n}\n\n.form textarea:hover,\n.form textarea:focus {\n  border-color: color-purple;\n  outline: none;\n}\n\n.form select {\n  border: none;\n  border-right: 0.5rem solid white;\n  outline: 1px solid color-gray;\n  padding: 0.5rem;\n  border-radius: 0.25rem;\n  transition: 0.3s;\n}\n\n.form select:hover,\n.form select:focus {\n  outline-color: color-purple;\n}\n\n.error {\n  background-color: color-error-light;\n  color: color-error;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  padding: 0.5rem;\n  border-radius: 0.25rem;\n}\n\n.button {\n  max-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-error-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"title": `Umhg2tGwg0vbX80MWQ0B`,
	"form": `c3zHZ5HwQbVEkdvTegcE`,
	"row": `pAIbdTUgokUms2zaBkL2`,
	"error": `_ifU2Deh43uiwnnx1lUc`,
	"button": `gZP8b6WrWWwANUPAisyy`
};
export default ___CSS_LOADER_EXPORT___;
