// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F9wnniLFUXmhxX2UPzH8 {
  display: flex;
  flex-direction: row;
}

.oVrG9NyNvAWtgJHHSeQu {
  max-width: 1440px;
  width: 90%;
  margin: auto;
}

.Jd3mj5KiZjoBFzDWOUe0 {
  padding: 24px;
}

@media screen and (max-width: 768px) {
  .n48W61Kfb9HuxU2LkXpK {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/library/style/layout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n}\n\n.container {\n  max-width: 1440px;\n  width: 90%;\n  margin: auto;\n}\n\n.section {\n  padding: 24px;\n}\n\n@media screen and (max-width: 768px) {\n  .desktop {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `F9wnniLFUXmhxX2UPzH8`,
	"container": `oVrG9NyNvAWtgJHHSeQu`,
	"section": `Jd3mj5KiZjoBFzDWOUe0`,
	"desktop": `n48W61Kfb9HuxU2LkXpK`
};
export default ___CSS_LOADER_EXPORT___;
