var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as types from './types';
var initialState = {
    list: [],
    autoDeleteTime: 10000,
};
export default function toastsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case types.ADD_TOAST:
            return __assign(__assign({}, state), { list: __spreadArray(__spreadArray([], state.list, true), [payload], false) });
        case types.REMOVE_TOAST:
            return __assign(__assign({}, state), { list: state.list.filter(function (item) { return item.id !== payload; }) });
        default:
            return state;
    }
}
