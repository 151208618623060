import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from './CustomerDataMainMeetings.module.css';
function Meeting(_a) {
    var meeting = _a.meeting;
    return (_jsxs("div", { className: styles.meeting, children: [_jsxs("div", { className: styles.time, children: [_jsx("p", { className: styles.date, children: new Date(meeting.date).toLocaleDateString('fr-FR') }), _jsx("p", { className: styles.hour, children: "".concat(new Date(meeting.date).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }), " - ").concat(meeting.time || 15, "min") })] }), _jsxs("div", { children: [_jsx("p", { className: styles.with, children: "Avec" }), _jsx("p", { className: styles.value, children: meeting.inCharge.username })] }), _jsxs("div", { children: [_jsx("p", { className: styles.with, children: "Motif" }), _jsx("p", { className: styles.value, children: meeting.title })] })] }));
}
export default function CustomerDataMainMeetings(_a) {
    var meetings = _a.meetings;
    return (_jsxs("div", { children: [_jsx("p", { className: styles.title, children: "Rendez-vous" }), (meetings.coming && meetings.coming.length > 0)
                && (_jsxs(_Fragment, { children: [_jsx("p", { className: styles.subtitle, children: "\u00C0 venir" }), meetings.coming.map(function (meeting) { return (_jsx(Meeting, { meeting: meeting }, meeting.date)); })] })), (meetings.archived && meetings.archived.length > 0)
                && (_jsxs("div", { className: styles.archived, children: [_jsx("p", { className: styles.subtitle, children: "Pass\u00E9" }), meetings.archived.map(function (meeting) { return (_jsx(Meeting, { meeting: meeting }, meeting.date)); })] }))] }));
}
