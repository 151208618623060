import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef, useEffect, useCallback, } from 'react';
import { AutoAwesome, Close, Facebook, Laptop, Person, Search, Warning, } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import useOutsideClickAlert from 'hooks/useOutsideClick';
import * as statuses from 'services/store/states';
import InputText from 'components/library/InputText';
import useAPI from 'hooks/useAPI';
import { getLeadsByQuery } from 'services/network/api/leads';
import { toLead } from 'services/network/adaptor/leads';
import styles from './LeadsToolbarSearch.module.css';
var USER_ICON = {
    sdr: _jsx(Facebook, { className: styles.icon }),
    reputation: _jsx(AutoAwesome, { className: styles.icon }),
    p2p: _jsx(Person, { className: styles.icon }),
    waiting_visio: _jsx(Laptop, { className: styles.icon }),
    default: _jsx(Person, { className: styles.icon }),
};
export default function LeadsToolbarSearch(_a) {
    var onSelect = _a.onSelect;
    var _b = useState(false), showResults = _b[0], setShowResults = _b[1];
    var _c = useState(''), query = _c[0], setQuery = _c[1];
    var results = useRef(null);
    useOutsideClickAlert(results, function () { return setShowResults(false); });
    var _d = useAPI({
        request: function (source, payload) { return getLeadsByQuery(source, payload || query); },
        adaptor: function (newLeads) { return newLeads.map(function (lead) { return toLead(lead); }); },
        wait: true,
    }), status = _d.status, data = _d.data, error = _d.error, fetchData = _d.fetchData, setData = _d.setData;
    var delayedSearch = useCallback(debounce(function (search) {
        fetchData({ payload: search });
    }, 500), []);
    useEffect(function () {
        if (query) {
            delayedSearch(query);
        }
    }, [query]);
    var onCancel = function () {
        setQuery('');
        setShowResults(false);
        setData(undefined);
    };
    var onQueryChange = function (e) {
        setQuery(e.target.value);
        setShowResults(true);
    };
    var onFocus = function () {
        if (query) {
            setShowResults(true);
        }
    };
    var onLeadSelected = function (e) {
        var id = e.currentTarget.dataset.id;
        if (id) {
            onSelect(data.find(function (lead) { return lead.id.toString() === id; }));
        }
    };
    var onEnter = function (e) {
        if (e.key === 'Enter') {
            onLeadSelected(e);
        }
    };
    return (_jsxs("div", { className: styles.container, children: [_jsx(InputText, { placeholder: "Recherche", className: styles.searchbar, value: query, onChange: onQueryChange, onFocus: onFocus, endChildren: (query !== '') && (_jsx("button", { type: "button", "aria-label": "Close", className: styles.close, onClick: onCancel, children: _jsx(Close, {}) })), children: _jsx(Search, {}) }), (showResults)
                && (_jsxs("div", { ref: results, className: styles.results, children: [(status === statuses.LOADING || status === statuses.IDLE || !data)
                            && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "fadeIn ".concat(styles.skeleton), children: [_jsx("div", { className: styles.icon }), _jsx("div", { className: styles.title })] }), _jsxs("div", { className: "fadeIn ".concat(styles.skeleton), children: [_jsx("div", { className: styles.icon }), _jsx("div", { className: styles.title })] })] })), ((status === statuses.SUCCESS) && (data === null || data === void 0 ? void 0 : data.length) === 0)
                            && (_jsxs("p", { className: styles.empty, children: ["Pas de r\u00E9sultat pour", _jsx("b", { children: query })] })), ((status === statuses.SUCCESS) && (data === null || data === void 0 ? void 0 : data.length) > 0)
                            && (data.map(function (lead) { return (_jsxs("div", { role: "button", tabIndex: -1, "data-id": lead.id, className: "fadeIn ".concat(styles.lead), onClick: onLeadSelected, onKeyDown: onEnter, children: [_jsx("div", { className: styles['icon-container'], children: USER_ICON[lead.type || 'default'] }), _jsxs("p", { className: styles['lead-text'], children: [lead.name || lead.email, _jsx("span", { children: lead.phone })] })] }, lead.id)); })), (status === statuses.FAIL)
                            && (_jsxs("div", { className: styles.error, children: [_jsx("div", { className: styles['icon-container'], children: _jsx(Warning, { className: styles.icon }) }), _jsxs("div", { className: styles.text, children: [_jsx("p", { children: "Une erreur est survenue" }), _jsx("p", { className: styles.description, children: error })] })] }))] }))] }));
}
