import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { OpenInNew } from '@mui/icons-material';
import { getCalendarUrl, getCalendarUrlRI } from 'services/controller/customers';
import styles from './CustomerDataMainProject.module.css';
export default function CustomerDataMainProject(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var project = _a.project, customer = _a.customer;
    return (_jsxs("div", { className: styles.project, children: [_jsx("h2", { className: styles.title, children: "Information relative \u00E0 la lev\u00E9e de fonds" }), _jsxs("div", { className: styles.row, children: [_jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Projet" }), _jsx("p", { children: project.name })] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "ID Projet" }), _jsx("p", { children: project.id })] }), (project.fundRaised)
                        && (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Montant recherch\u00E9" }), _jsx("p", { children: "".concat((((_b = project === null || project === void 0 ? void 0 : project.fundRaised) === null || _b === void 0 ? void 0 : _b.amountSought) || 0).toLocaleString(), "\u20AC") })] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Status de la campagne" }), _jsx("p", { children: (project === null || project === void 0 ? void 0 : project.status) || 'N/A' })] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Nombre d'investisseurs" }), _jsx("p", { children: ((_c = project === null || project === void 0 ? void 0 : project.fundRaised) === null || _c === void 0 ? void 0 : _c.investors) || 'N/A' })] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Montant lev\u00E9e" }), _jsx("p", { children: "".concat((_e = (((_d = project.fundRaised) === null || _d === void 0 ? void 0 : _d.amountRaised) || 0)) === null || _e === void 0 ? void 0 : _e.toLocaleString(), "\u20AC") })] })] })), (project.importantDate)
                        && (_jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Date de lancement" }), _jsx("p", { children: ((_f = project.importantDate) === null || _f === void 0 ? void 0 : _f.realStartDate) || 'N/A' })] }))] }), (project.inCharge)
                && (_jsxs(_Fragment, { children: [_jsx("h2", { className: styles.title, children: "\u00C9quipe en charge" }), _jsxs("div", { className: styles.row, children: [_jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Sales" }), ((_h = (_g = project.inCharge) === null || _g === void 0 ? void 0 : _g.sales) === null || _h === void 0 ? void 0 : _h.calendar)
                                            ? (_jsxs("a", { href: getCalendarUrl(project.inCharge.sales.calendar, customer), target: "_blank", rel: "noreferrer", children: [project.inCharge.sales.username, _jsx(OpenInNew, {})] }))
                                            : (_jsx("p", { children: project.inCharge.sales.username || 'N/A' }))] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Juridique" }), ((_k = (_j = project.inCharge) === null || _j === void 0 ? void 0 : _j.legal) === null || _k === void 0 ? void 0 : _k.calendar)
                                            ? (_jsxs("a", { href: getCalendarUrl(project.inCharge.legal.calendar, customer), target: "_blank", rel: "noreferrer", children: [project.inCharge.legal.username, _jsx(OpenInNew, {})] }))
                                            : (_jsx("p", { children: project.inCharge.legal.username || 'N/A' }))] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Marketing" }), ((_m = (_l = project.inCharge) === null || _l === void 0 ? void 0 : _l.market) === null || _m === void 0 ? void 0 : _m.calendar)
                                            ? (_jsxs("a", { href: getCalendarUrl(project.inCharge.market.calendar, customer), target: "_blank", rel: "noreferrer", children: [project.inCharge.market.username, _jsx(OpenInNew, {})] }))
                                            : (_jsx("p", { children: project.inCharge.market.username || 'N/A' }))] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Relation investisseur" }), ((_p = (_o = project.inCharge) === null || _o === void 0 ? void 0 : _o.ri) === null || _p === void 0 ? void 0 : _p.calendar)
                                            ? (_jsxs("a", { href: getCalendarUrlRI(project.inCharge.ri.calendar, customer), target: "_blank", rel: "noreferrer", children: [project.inCharge.ri.username, _jsx(OpenInNew, {})] }))
                                            : (_jsx("p", { children: project.inCharge.ri.username || 'N/A' }))] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Closing" }), ((_r = (_q = project.inCharge) === null || _q === void 0 ? void 0 : _q.closing) === null || _r === void 0 ? void 0 : _r.calendar)
                                            ? (_jsxs("a", { href: getCalendarUrl(project.inCharge.closing.calendar, customer), target: "_blank", rel: "noreferrer", children: [project.inCharge.closing.username, _jsx(OpenInNew, {})] }))
                                            : (_jsx("p", { children: project.inCharge.closing.username || 'N/A' }))] })] })] }))] }));
}
