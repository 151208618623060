var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckCircle } from '@mui/icons-material';
import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import { addToast } from 'services/store/features/toasts/actions';
import { fromBackCustomerComment, toBackCommentCustomer } from 'services/network/adaptor/customers';
import getErrorMessage from 'utils/error';
import { createNewComment, updateCommentById } from 'services/network/api/customers';
import styles from './CustomerDataMainComment.module.css';
import commentsReducer from './commentsReducer.js';
import { logError } from 'utils/logs';
export default function CustomerDataMainComment(_a) {
    var _this = this;
    var comments = _a.comments, id = _a.id;
    var _b = useReducer(commentsReducer, { comments: comments }), state = _b[0], localDispatch = _b[1];
    var _c = useState(''), newComment = _c[0], setNewComment = _c[1];
    var dispatch = useDispatch();
    var handleComment = function (e) { return setNewComment(e.target.value); };
    var markAsResolved = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var value, commentId, commentToUpdate, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    value = e.currentTarget.dataset.id;
                    commentId = value
                        ? Number.parseInt(value, 10)
                        : undefined;
                    commentToUpdate = state.comments.find(function (comment) { return comment.id === commentId; });
                    if (!(commentId && commentToUpdate)) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateCommentById(toBackCommentCustomer(__assign(__assign({}, state.comments.find(function (comment) { return comment.id === commentId; })), { isResolved: !commentToUpdate.isResolved })), commentId)];
                case 2:
                    _a.sent();
                    localDispatch({ type: 'markAsResolved', payload: commentId });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de marquer le commentaire comme résolu',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de marquer le commentaire comme résolu',
                        description: 'Comment id not found',
                    }));
                    _a.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var addComment = function () { return __awaiter(_this, void 0, void 0, function () {
        var data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createNewComment(toBackCommentCustomer({
                            idUser: id,
                            title: '',
                            text: newComment,
                            isResolved: false,
                            status: 'error',
                        }))];
                case 1:
                    data = (_a.sent()).data;
                    localDispatch({ type: 'addComment', payload: fromBackCustomerComment(data) });
                    setNewComment('');
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    logError(err_2);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible de publier le commentaire',
                        description: getErrorMessage(err_2),
                    }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: styles.comments, children: [_jsx("p", { className: styles.title, children: "Commentaires" }), (state.comments && state.comments.length > 0)
                && (state.comments.map(function (comment) { return (_jsxs("div", { className: styles.comment, children: [_jsx("p", { className: styles.logo, children: comment.owner.username[0] }), _jsxs("div", { className: styles.main, children: [_jsxs("div", { className: styles.header, children: [_jsx("p", { className: styles.username, children: comment.owner.username }), _jsx(Button, { variant: "ghost", className: "".concat(styles.custom, " ").concat(comment.isResolved ? styles.success : ''), id: comment.id, onClick: markAsResolved, children: (!comment.isResolved)
                                                ? (_jsxs(_Fragment, { children: [_jsx(CheckCircle, { className: styles.icon }), _jsx("span", { children: "Mark as resolved" })] }))
                                                : (_jsxs(_Fragment, { children: [_jsx(CheckCircle, { className: styles.icon }), _jsx("span", { children: "Resolved" })] })) })] }), _jsx("p", { className: styles.text, children: comment.text })] })] }, comment.id)); })), _jsx(InputText, { placeholder: "Commentaires...", value: newComment, onChange: handleComment, className: styles.input }), _jsx(Button, { onClick: addComment, className: styles.submit, disabled: newComment === '', children: "Envoyer" })] }));
}
