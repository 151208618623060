import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Edit, Upload } from '@mui/icons-material';
import Button from '../../library/Button';
import styles from '../AddInvestLead.module.css';
export var AddInvestLeadType;
(function (AddInvestLeadType) {
    AddInvestLeadType["manual"] = "manual";
    AddInvestLeadType["csv"] = "csv";
    AddInvestLeadType["idle"] = "idle";
})(AddInvestLeadType || (AddInvestLeadType = {}));
function AddLeadInvestType(_a, ref) {
    var setType = _a.setType, onClose = _a.onClose;
    return (_jsx("div", { className: styles.container, children: _jsxs("div", { className: styles.modal, ref: ref, tabIndex: -1, children: [_jsx("h2", { className: styles.title, children: "Comment souhaitez-vous ajouter un investisseur ?" }), _jsxs("div", { className: styles.row, children: [_jsxs("button", { type: "button", className: styles.choice, onClick: function () { return setType(AddInvestLeadType.manual); }, children: [_jsx("span", { className: styles['choice-icon'], children: _jsx(Edit, {}) }), _jsx("span", { className: styles['choice-title'], children: "Ajout manuel" }), _jsx("span", { className: styles['choice-description'], children: "Entrer les informations de l'investisseur manuellement" })] }), _jsxs("button", { type: "button", className: styles.choice, onClick: function () { return setType(AddInvestLeadType.csv); }, children: [_jsx("span", { className: styles['choice-icon'], children: _jsx(Upload, {}) }), _jsx("span", { className: styles['choice-title'], children: "Import CSV" }), _jsx("span", { className: styles['choice-description'], children: "Importer un fichier CSV contenant les informations des investisseurs" })] })] }), _jsx(Button, { variant: "outline", onClick: onClose, children: "Annuler" })] }) }));
}
export default forwardRef(AddLeadInvestType);
