// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZF0c01b5ITFhNw2_p77 {
  border: none;
  background: none;
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

.KJdUGH4tlcyW9jyscPgQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-height: 32px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.KJdUGH4tlcyW9jyscPgQ:focus-within {
  border-color: #526af2;
  color: #526af2;
}
`, "",{"version":3,"sources":["webpack://./src/components/library/InputText/InputText.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;EACR,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".input {\n  border: none;\n  background: none;\n  outline: none;\n  height: 100%;\n  width: 100%;\n  font-size: 14px;\n  font-family: Roboto, sans-serif;\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 4px;\n  min-height: 32px;\n  padding: 8px;\n  box-sizing: border-box;\n  border: 1px solid #e6e6e6;\n  border-radius: 5px;\n}\n\n.container:focus-within {\n  border-color: #526af2;\n  color: #526af2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `AZF0c01b5ITFhNw2_p77`,
	"container": `KJdUGH4tlcyW9jyscPgQ`
};
export default ___CSS_LOADER_EXPORT___;
