// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oo9mpxNJPxI8yFiaD0ZD {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.jJ8EAzY0E4ECBHRcslJs {
    width: 100%;
    grid-area: record;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerData/CustomerDataMain/CustomerDataMainCallRecord/CustomerDataMainCallRecord.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".title {\n    font-weight: 500;\n    font-size: 1.125rem;\n    margin-bottom: 0.5rem;\n}\n\n.record {\n    width: 100%;\n    grid-area: record;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `oo9mpxNJPxI8yFiaD0ZD`,
	"record": `jJ8EAzY0E4ECBHRcslJs`
};
export default ___CSS_LOADER_EXPORT___;
