import axios from 'services/network/axiosClient';
export var getCustomersByQuery = function (source, _a) {
    var query = _a.query, _b = _a.type, type = _b === void 0 ? 'all' : _b;
    if (!query) {
        return axios.get('/get_favorites', { cancelToken: source.token });
    }
    return axios.get('/search_user', { params: { search: query, type: type }, cancelToken: source.token });
};
export var getCustomerById = function (source, id, email) { return axios.get("/user/".concat(id), { params: { email: email }, cancelToken: source.token }); };
export var updateCustomerById = function (data) { return axios.post('/user', data); };
export var createCustomer = function (data) { return axios.post('/user', data); };
export var createNewComment = function (data) { return axios.post('/comment', data); };
export var updateCommentById = function (data, id) { return axios.put("/comment/".concat(id), data); };
export var callBackUser = function (data) { return axios.post('/call_back_user', data); };
export var callBackUserRi = function (data) { return axios.post('/call_back_user', data); };
