// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UUoiQh7KFW9WxxskEZ8v {
  font-weight: 500;
}

.bPgpvVEP6MpU01g1if8Q {
  width: 100%;
  min-height: 42px;
  height: 100px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  font-family: Roboto, sans-serif;
}

.CZpCngcSGtwtnYInNgNE {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.A77GcSP8whLo3aZqj0Z7 {
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f1fb;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.A77GcSP8whLo3aZqj0Z7 p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/Leads/LeadsTunnel/LeadsTunnelCustomer/LeadsTunnelCustomerComment/LeadsTunnelCustomerComment.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".title {\n  font-weight: 500;\n}\n\n.input {\n  width: 100%;\n  min-height: 42px;\n  height: 100px;\n  padding: 8px;\n  box-sizing: border-box;\n  border-radius: 5px;\n  border: 1px solid #e5e5e5;\n  font-family: Roboto, sans-serif;\n}\n\n.comments {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.comment {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #f0f1fb;\n  border-radius: 5px;\n  padding: 0.5rem;\n  margin-bottom: 1rem;\n}\n\n.comment p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `UUoiQh7KFW9WxxskEZ8v`,
	"input": `bPgpvVEP6MpU01g1if8Q`,
	"comments": `CZpCngcSGtwtnYInNgNE`,
	"comment": `A77GcSP8whLo3aZqj0Z7`
};
export default ___CSS_LOADER_EXPORT___;
