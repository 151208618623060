import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowBack } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import useAPI from 'hooks/useAPI';
import Loading from 'components/library/Loading';
import { getCustomerById } from 'services/network/api/customers';
import { fromBackCustomer } from 'services/network/adaptor/customers';
import LoadingSpinner from 'components/library/LoadingSpinner';
import layout from 'components/library/style/layout.module.css';
import CustomerDataHeader from './CustomerDataHeader';
import CustomerDataMain from './CustomerDataMain';
import styles from './CustomerDataHeader/CustomerDataHeader.module.css';
export default function CustomerData(_a) {
    var idAyomi = _a.idAyomi, email = _a.email;
    var _b = useAPI({
        request: function (source) { return getCustomerById(source, idAyomi, email); },
        adaptor: fromBackCustomer,
    }), status = _b.status, data = _b.data;
    return (_jsx(Loading, { status: status, fallback: _jsx(LoadingSpinner, {}), errorFallback: (_jsxs("div", { className: "".concat(layout.container, " ").concat(layout.section), children: [_jsxs(NavLink, { to: "/search", className: styles.back, children: [_jsx(ArrowBack, { className: styles.icon }), _jsx("span", { children: "Retour aux r\u00E9sultats" })] }), _jsx("p", { children: " Aucune information trouv\u00E9e " })] })), children: (data)
            ? (_jsxs(_Fragment, { children: [_jsx(CustomerDataHeader, { customer: data }), _jsx(CustomerDataMain, { customer: data })] }))
            : (_jsx("p", { children: "Aucune information trouv\u00E9e" })) }));
}
