var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import * as yup from 'yup';
import * as statuses from 'services/store/states';
import { createWebinarRdv } from 'services/network/api/leads';
import Button from '../library/Button';
import SuccessAnimation from '../library/SuccessAnimation/SuccessAnimation';
import styles from './VisioScheduler.module.css';
import { logError } from 'utils/logs';
var dateInFuture = yup.date().min(new Date(), 'La date doit être dans le futur');
export default function VisioScheduler(_a) {
    var _this = this;
    var email = _a.email, projectId = _a.projectId, onClose = _a.onClose;
    var _b = useState(statuses.IDLE), status = _b[0], setStatus = _b[1];
    var _c = React.useState(new Date().toISOString().split('T')[0] || ''), date = _c[0], setDate = _c[1];
    var _d = useState(''), error = _d[0], setError = _d[1];
    var submit = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dateInFuture.isValidSync(date)) {
                        setError('La date doit être dans le futur');
                        return [2 /*return*/];
                    }
                    setError('');
                    setStatus(statuses.LOADING);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createWebinarRdv(email, projectId, date)];
                case 2:
                    _a.sent();
                    setStatus(statuses.SUCCESS);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    setStatus(statuses.FAIL);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (status === statuses.SUCCESS) {
        return (_jsx("div", { className: styles.background, children: _jsxs("section", { className: styles.modal, children: [_jsx(SuccessAnimation, {}), _jsx("h2", { className: styles.title, children: "Votre rendez-vous visioconf\u00E9rence a bien \u00E9t\u00E9 pris en compte" }), _jsx(Button, { onClick: onClose, className: styles.close, children: "Fermer" })] }) }));
    }
    return (_jsx("div", { className: styles.background, children: _jsxs("section", { className: styles.modal, "data-testid": "webinar-modal", children: [_jsx("h1", { className: styles.title, children: "Prise de rendez-vous visio" }), status === statuses.FAIL
                    && (_jsx("p", { className: styles.fail, children: "Une erreur est survenue, veuillez r\u00E9essayer." })), _jsx("input", { type: "date", value: date, onChange: function (e) {
                        setDate(e.target.value);
                    }, className: "".concat(styles.date, " ").concat(error ? styles.error : ''), "aria-label": "webinar date", "data-testid": "webinar-date" }), error
                    && (_jsx("p", { className: styles['error-message'], children: error })), _jsxs("div", { className: styles['button-group'], children: [status !== statuses.LOADING
                            && (_jsx(Button, { type: "custom", className: styles.cancel, onClick: onClose, children: "Annuler" })), _jsx(Button, { onClick: submit, disabled: status === statuses.LOADING, children: status === statuses.LOADING
                                ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "spinner ".concat(styles.spinner) }), "Prise de rendez-vous en cours..."] }))
                                : 'Prendre rendez-vous' })] })] }) }));
}
