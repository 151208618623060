var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { keysToCamel } from 'utils/converter';
export var fromBackTeam = function (data) { return ({
    calendar: (data === null || data === void 0 ? void 0 : data.calendly) || undefined,
    username: "".concat((data === null || data === void 0 ? void 0 : data.first_name) || 'N/A', " ").concat((data === null || data === void 0 ? void 0 : data.last_name) || ''),
    email: (data === null || data === void 0 ? void 0 : data.email) || 'N/A',
    iss: (data === null || data === void 0 ? void 0 : data.iss) || 0,
    number: (data === null || data === void 0 ? void 0 : data.number) || 'N/A',
}); };
export var fromBackInCharge = function (data) { return ({
    closing: fromBackTeam(data.closing),
    legal: fromBackTeam(data.legal),
    market: fromBackTeam(data.market),
    ri: fromBackTeam(data.ri),
    sales: fromBackTeam(data.sales),
}); };
export var fromBackProject = function (data) {
    var _a;
    return (__assign(__assign({}, keysToCamel(data)), { id: data.id_ayomi || 0, fundRaised: __assign(__assign({}, keysToCamel(data.fund_raised)), { amountRaised: (_a = data.fund_raised) === null || _a === void 0 ? void 0 : _a.amount_escrow_acct }), inCharge: (data === null || data === void 0 ? void 0 : data.in_charge)
            ? fromBackInCharge(data === null || data === void 0 ? void 0 : data.in_charge)
            : undefined }));
};
export var fromBackProjects = function (data) {
    if (data && data.length > 0) {
        return (data
            .map(function (project) { return fromBackProject(project); }));
    }
    return [];
};
export var fromBackMeeting = function (data) { return ({
    date: data.date,
    title: data.template,
    time: data.time,
    inCharge: {
        username: data.user,
        calendar: 'N/A',
        email: 'N/A',
        iss: 0,
        number: 'N/A',
    },
}); };
export var fromBackMeetings = function (data) { return ({
    coming: (data.next_rdv ? [fromBackMeeting(data.next_rdv)] : []),
    archived: (data.last_rdv ? [fromBackMeeting(data.last_rdv)] : []),
}); };
export var fromBackCustomerComment = function (data) { return ({
    owner: {
        username: "".concat(data.id_user),
    },
    isResolved: data.resolved,
    text: "".concat(data.title, " ").concat(data.text),
    id: data.id,
    idUser: data.idUser,
}); };
export var fromBackCustomerComments = function (data) {
    if (data && data.length > 0) {
        return (data
            .map(function (comment) { return fromBackCustomerComment(comment); }));
    }
    return [];
};
export var fromBackCustomer = function (data) { return (__assign(__assign({}, keysToCamel(data)), { idAyomi: data.id_ayomi || 0, phone: data.number, username: "".concat(data.first_name || '', " ").concat(data.last_name || ''), isBookmarked: data.favorite, tags: data.type, projects: (data.projects) ? fromBackProjects(data.projects) : [], p2p: (data.project) ? fromBackProject(data.project) : undefined, meetings: (data.last_rdv || data.next_rdv) ? fromBackMeetings(data) : undefined, comments: (data.comments) ? fromBackCustomerComments(data.comments) : [] })); };
export var fromBackCustomers = function (data) {
    if (data && data.length > 0) {
        return (data
            .map(function (customer) { return (fromBackCustomer(customer)); }));
    }
    return [];
};
export var toBackCustomer = function (data) { return ({
    iss: data === null || data === void 0 ? void 0 : data.iss,
    email: data === null || data === void 0 ? void 0 : data.email,
    id_ayomi: data === null || data === void 0 ? void 0 : data.idAyomi,
    type: data === null || data === void 0 ? void 0 : data.type,
    favorite: data === null || data === void 0 ? void 0 : data.isBookmarked,
}); };
export var toBackCommentCustomer = function (data) { return ({
    id_user: data.idUser,
    title: data.title || '',
    text: data.text,
    resolved: data.isResolved,
    status: data.status || 'error',
}); };
