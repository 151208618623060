var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from '@mui/material';
import Button from '../library/Button';
import styles from './AddInvestLead.module.css';
import AddLeadInvestManual from './AddLeadInvestManual';
import AddLeadInvestType from './AddLeadInvestType';
import { AddInvestLeadType } from './AddLeadInvestType/AddLeadInvestType';
import AddLeadInvestCsv from './AddLeadInvestCsv';
var AddInvestLeadView = (_a = {},
    _a[AddInvestLeadType.idle] = AddLeadInvestType,
    _a[AddInvestLeadType.manual] = AddLeadInvestManual,
    _a[AddInvestLeadType.csv] = AddLeadInvestCsv,
    _a);
export default function AddInvestLead() {
    var _a = useState(AddInvestLeadType.idle), type = _a[0], setType = _a[1];
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var openModal = function () {
        setIsOpen(true);
    };
    var closeModal = function () {
        setType(AddInvestLeadType.idle);
        setIsOpen(false);
    };
    var AddInvestLeadComponent = AddInvestLeadView[type];
    return (_jsxs(_Fragment, { children: [_jsx(Button, { className: styles.small, onClick: openModal, children: "Ajouter un investisseur" }), isOpen && (_jsx(Modal, { open: isOpen, children: _jsx(AddInvestLeadComponent, { onClose: closeModal, setType: setType }) }))] }));
}
